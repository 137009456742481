import { backendService } from 'services';

export const getFrameworks = async () => {
  return await backendService.get(`/frameworks`);
};

export const getControlsGrouped = async (frameworksIds) => {
  const queryString = frameworksIds.join(",");
  return await backendService.get(`/controls-framework/controls-grouped?frameworksIds=${queryString}`);
};

export const getGapAnalysisControl = async (idGap, idControl) => {
  return await backendService.get(`/gap-analysis/${idGap}/controls-framework/${idControl}`);
}

export const updateMaturityLevel = async (idControl, maturityLevel) => {
  return await backendService.put(`/gap-analysis/control/${idControl}/maturity`, { maturityLevel, });
};

export const updateStatusControl = async (idControl, status) => {
  return await backendService.put(`/gap-analysis/control/${idControl}/status`, { status, });
};

export const updateStatusObjectiveControl = async (idObjective, objective) => {
  return await backendService.put(`/gap-analysis/objective/${idObjective}/status`, { objective });
};


export const postGapAnalysis  = async (data) => {
  return await backendService.post(`/gap-analysis`, data);
};

export const getGapAnalysis = async (tenant) => {
  return await backendService.get(`/gap-analysis?companyKey=${tenant}`)
}

export const getGapAnalysisDetails = async (id) => {
  return await backendService.get(`/gap-analysis/${id}/controls`)
}

export const getGapAnalysisControlsDetails = async (id) => {
  return await backendService.get(`/gap-analysis/${id}/controls-framework`)
}

export const getGapAnalysisControlDetails = async (id) => {
  return await backendService.get(`/gap-analysis/control/${id}`)
}

export const postGapAnalysisVinculo = async (id, data) => {
  return await backendService.post(`/gap-analysis/${id}/vinculo`, data);
};

export const deleteGapAnalysisVinculo = async (id_vinculo) => {
  return await backendService.delete(`/gap-analysis/vinculo/${id_vinculo}`);
};