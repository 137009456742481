import { Container } from 'Components/Container';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { deleteGapAnalysisVinculo, getGapAnalysisDetails } from '../../../services/gap-analysis';

import { PageLoaderShow } from '../../../Components/PageLoader/Show';
import { ExpandableTable } from '../../../Components/ExpandableTable';
import { tableHeader } from './table-header';
import { tableEntity } from './table-entity';
import { Information } from '../../../Components/Information';
import { StatusProgressBar  } from '../../../Components/StatusProgressBar';
import { Card } from '../../../Components/Card';
import { Alert, Box, Snackbar } from '@mui/material';
import { Title } from './ControlDetal/styles';
import { ButtonAdd } from '../../../Components/Container/styles';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Column, Row } from '../../../Components/Grid';
import { ModalConfirmDelete } from '../../../Components/ModalConfirmDelete';


const statusData: Record<string, { label: string; color: string; description: string }> = {
  "noEntities": {
    label: "Não Vinculado",
    color: "#9E9E9E",
    description: "O controle não está vinculado a nenhuma entidade específica."
  },
  "noResponse": {
    label: "Sem resposta",
    color: "#757575",
    description: "O controle ainda não foi avaliado e nenhuma resposta foi fornecida."
  },
  "deficient": {
    label: "Deficiente",
    color: "#D32F2F",
    description: "O controle não está implementado ou está parcialmente implementado."
  },
  "implemented": {
    label: "Implementado",
    color: "#FF9800",
    description: "O controle está implementado, mas ainda não foi testado ou verificado."
  },
  "readyForReview": {
    label: "Pronto para Revisão",
    color: "#FFC107",
    description: "Evidências foram coletadas e o controle está pronto para revisão por um avaliador."
  },
  "monitored": {
    label: "Monitorado",
    color: "#4CAF50",
    description: "O controle foi avaliado e está em um estado de monitoramento contínuo."
  },
  "notApplicable": {
    label: "Não Aplicável",
    color: "#72ba76",
    description: "O controle não se aplica a este contexto ou ambiente."
  }
};

const descriptionContent = (
  <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", gap: "4px" }}>
    {Object.entries(statusData).map(([key, { label, color, description }]) => (
      <div key={key} style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <div style={{ width: "16px", height: "16px", backgroundColor: color, borderRadius: "4px" }}></div>
        <strong>{label}</strong> - {description}
      </div>
    ))}
  </div>
);


export const GapAnalysisShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const {
    isLoading,
    error,
    refetch,
    data: gapAnalysis,
  } = useQuery('getGapAnalysisDetails', async () => {
    const data = await getGapAnalysisDetails(id);
    return data.status === 200 ? data.data : null;
  });
  const [filters, setFilters] = useState({
    peso: '',
    dominio: '',
    vinculo: '',
    entidadeTipo: '',
    entidadeNome: '',
    status: '',
  });

  if (isLoading) return <p>Carregando...</p>;
  if (error) return <p>Erro ao carregar os dados.</p>;
  const formattedStatuses = Object.keys(statusData).map(status => ({
    label: statusData[status].label,
    count: gapAnalysis.statusCounts[status] || 0,
    color: statusData[status].color,
  }));


  const uniqueValues = (key: string) => [...new Set(gapAnalysis.controls.map(control => control[key]))].sort();

  const getUniqueEntities = () => {
    const entityMap = new Map();
    const entityTypes = new Set();
    const entityStatuses = new Set();
    const maturityLevels = new Set();

    gapAnalysis.controls.forEach(control => {
      control.entities.forEach(entity => {
        const key = `${entity.entity_id}-${entity.entity_type}`;

        if (!entityMap.has(key)) {
          entityMap.set(key, {
            entity_id: entity.entity_id,
            entity_name: entity.entity_name,
          });
        }

        entityTypes.add(entity.entity_type);
        entityStatuses.add(entity.status);
        maturityLevels.add(entity.maturity_level);

      });
    });

    return {
      entities: Array.from(entityMap.values()),
      entityTypes: Array.from(entityTypes),
      entityStatuses: Array.from(entityStatuses),
      maturityLevels: Array.from(maturityLevels),

    };
  };

  const { entities, entityTypes, entityStatuses, maturityLevels  } = getUniqueEntities();

  const handleFilterChange = (field: string, value: string) => {
    setFilters(prev => ({ ...prev, [field]: value }));
  };
  const typeLabels = {
    areas: "Áreas de Negócio",
    processes: "Processos",
    systems: "Sistemas",
    assets: "Ativos",
    companies: "Empresa",
  };
  const statusOptions = {
    noResponse: "Sem resposta",
    deficient: "Deficiente",
    implemented: "Implementado",
    readyForReview: "Pronto para revisão",
    monitored: "Monitorado",
    notApplicable: "Não Aplicável"
  };
  const maturityLevelsOptions = {
    notPerformed: "Nível 1",
    performedInformally: "Nível 2",
    plannedTracked: "Nível 3",
    wellDefined: "Nível 4",
    quantitativelyControlled: "Nível 5",
    continuouslyImproving: "Nível 6",
  };
  const filteredControls = gapAnalysis.controls
    .map(control => {
      // Se houver filtros de entidade, filtramos as entidades dentro do controle
      const filteredEntities = filters.entidadeNome || filters.entidadeTipo || filters.maturityLevel || filters.status
        ? control.entities.filter(entity => (
          (filters.entidadeTipo ? entity.entity_type === filters.entidadeTipo : true) &&
          (filters.entidadeNome ? entity.entity_id === filters.entidadeNome : true) &&
          (filters.maturityLevel ? entity.maturity_level === filters.maturityLevel : true) &&
          (filters.status ? entity.status === filters.status : true)
        ))
        : control.entities; // Se não houver filtros de entidade, mantém todas as entidades

      return {
        ...control,
        entities: filteredEntities, // Substituímos as entidades pelo resultado filtrado
      };
    })
    .filter(control =>
      // Mantém os controles mesmo se não tiver entidades, caso não haja filtros de entidade
      (control.entities.length > 0 || !(filters.entidadeNome || filters.entidadeTipo || filters.maturityLevel || filters.status)) &&
      (filters.peso ? parseInt(control.controlWeighting, 10) === parseInt(filters.peso, 10) : true) &&
      (filters.dominio ? control.scfDomain === filters.dominio : true) &&
      (filters.vinculo ? control.vinculado.toString() === filters.vinculo : true)
    );


  const button = <ButtonAdd
    label="+ Vincular Controles"
    className="no-margin"
    onClick={() => navigate('vincular')}
  />
  const link_ = `gap-analysis/${id}`;

  const onDelete = item => {
    setItemToDelete(item);
    setOpenDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setItemToDelete(null);
  };
  const callDeleteAction = async (itemToDelete, onClose, refetch, setSnackbar,) => {
    try {
      await deleteGapAnalysisVinculo(itemToDelete._id);
      setSnackbar({ open: true, message: "Vínculo removido com sucesso!", severity: "success" });
      refetch();
      onClose();
    } catch (error) {
      setSnackbar({ open: true, message: "Erro ao remover o vínculo.", severity: "error" });
    }
  };


  const onConfirmDeleteModal = async () => {
    try {
      await callDeleteAction(itemToDelete, onCloseDeleteModal, refetch, setSnackbar);
    } catch (error) {
      onCloseDeleteModal();

      setSnackbar({
        open: true,
        message: error.response?.data?.message ?? "Erro ao remover o vínculo.",
        severity: "error",
      });
    }
  };


  return (
    <>
      {!isLoading && !error && (
        <Container title={`${gapAnalysis.gapAnalysis?.name}`}
                   breadcrumb={`Assessment / Gap Analysis / ${gapAnalysis.gapAnalysis?.name}`}
                   linkPage="/gap-analysis"
                   actionButton={button}
        >
          <Box sx={{ marginBottom: '10px', marginTop: '10px' }}>
            <Card>
              <Title className="m-0 mb-2">Status de Implementação</Title>
              <StatusProgressBar statuses={formattedStatuses} />
            </Card>
          </Box>

          <Information
            title={'Descrição dos Status de Implementação'}
            description={descriptionContent}
            style={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          />


          <Card className="mb-2 mt-3">
            <Row className="mb-3" md={'3'}>
              <Column xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="dominio-label" shrink>Domínio</InputLabel>
                  <Select
                    labelId="dominio-label"
                    variant="outlined"
                    label="Domínio"
                    notched
                    sx={{
                      minHeight: '40px',
                      maxHeight: '40px',
                      margin: '0 auto',
                      width: '100%',
                    }}
                    value={filters.dominio} onChange={(e) => handleFilterChange("dominio", e.target.value)}>
                    <MenuItem value="">Todos</MenuItem>
                    {uniqueValues('scfDomain').map(value => (
                      <MenuItem key={value} value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Column>
              <Column xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="vinculo-label" shrink>Vínculo</InputLabel>
                  <Select
                    labelId="vinculo-label"
                    variant="outlined"
                    label="Vínculo"
                    notched
                    sx={{
                      minHeight: '40px',
                      maxHeight: '40px',
                      margin: '0 auto',
                      width: '100%',
                    }}
                    value={filters.vinculo} onChange={(e) => handleFilterChange("vinculo", e.target.value)}>
                    <MenuItem value="">Todos</MenuItem>
                    <MenuItem value="true">Sim</MenuItem>
                    <MenuItem value="false">Não</MenuItem>
                  </Select>
                </FormControl>
              </Column>
              <Column xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel  id="status-label" shrink>Status</InputLabel>
                  <Select
                    labelId="status-label"
                    variant="outlined"
                    label="Status"
                    notched
                    sx={{
                      minHeight: '40px',
                      maxHeight: '40px',
                      margin: '0 auto',
                      width: '100%',
                    }}
                    value={filters.status} onChange={(e) => handleFilterChange("status", e.target.value)}>
                    <MenuItem value="">Todos</MenuItem>
                    {entityStatuses.map(status => (
                      <MenuItem key={status} value={status}>{statusOptions[status]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Column>
            </Row>
            <Row className="mb-3" md={'3'}>
              <Column xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel  id="name-entity-label" shrink >Nome da Entidade</InputLabel>
                  <Select
                    labelId="name-entity-label"
                    label="Nome da Entidade"
                    variant="outlined"
                    notched
                    sx={{
                      minHeight: '40px',
                      maxHeight: '40px',
                      margin: '0 auto',
                      width: '100%',
                    }}
                    value={filters.entidadeNome} onChange={(e) => handleFilterChange("entidadeNome", e.target.value)}>
                    <MenuItem value="">Todas</MenuItem>
                    {entities.map((entity) => (
                      <MenuItem key={entity.entity_id} value={entity.entity_id}>
                        {entity.entity_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Column>
              <Column xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel  id="type-entity-label" shrink >Tipo de Entidade</InputLabel>
                  <Select
                    labelId="type-entity-label"
                    label="Tipo de Entidade"
                    variant="outlined"
                    notched
                    sx={{
                      minHeight: '40px',
                      maxHeight: '40px',
                      margin: '0 auto',
                      width: '100%',
                    }}
                    value={filters.entidadeTipo} onChange={(e) => handleFilterChange("entidadeTipo", e.target.value)}>
                    <MenuItem value="">Todos</MenuItem>
                    {entityTypes.map(type => (
                      <MenuItem key={type} value={type}>{typeLabels[type]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Column>
              <Column xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="maturity-type-label" shrink >Nível de Maturidade</InputLabel>
                  <Select
                    labelId="maturity-type-label"
                    label="Nível de Maturidade"
                    notched
                    variant="outlined"
                    sx={{
                      minHeight: '40px',
                      maxHeight: '40px',
                      margin: '0 auto',
                      width: '100%',
                    }}
                    value={filters.maturityLevel} onChange={(e) => handleFilterChange("maturityLevel", e.target.value)}>
                    <MenuItem value="">Todos</MenuItem>
                    {maturityLevels.map(level => (
                      <MenuItem key={level} value={level}>{maturityLevelsOptions[level]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Column>
              <Column xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="peso-label"  shrink>Peso do Controle</InputLabel>
                  <Select
                    labelId="peso-label"
                    label="Peso do Controle"
                    notched
                    sx={{
                      minHeight: '40px',
                      maxHeight: '40px',
                      margin: '0 auto',
                      width: '100%',
                    }}
                    variant="outlined"
                    value={filters.peso} onChange={(e) => handleFilterChange("peso", e.target.value)}
                  >
                    <MenuItem value="">Todos</MenuItem>
                    {uniqueValues('controlWeighting').map(value => (
                      <MenuItem key={value} value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Column>
            </Row>
          </Card>
          <ExpandableTable
            rows={filteredControls}
            columns={tableHeader}
            link={link_}
            linkExtend="gap-analysis/control"
            entityColumns={tableEntity}
            enableSearch={true}
            enableSorting={true}
            title="Controles"
            onClickDelete={onDelete}
          />

          <ModalConfirmDelete
            description={
              <>
                Tem certeza que deseja desvincular a entidade <strong>{` ${itemToDelete?.entity_name} `}</strong> deste controle?
              </>
            }
            open={openDeleteModal}
            onClose={onCloseDeleteModal}
            onConfirm={onConfirmDeleteModal}
          />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  );
};
