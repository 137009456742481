import React, { useState } from "react";
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  Tooltip
} from "@mui/material";

const ItemSelector = ({
                        config,
                        items,
                        searchTerm,
                        setSearchTerm,
                        setValue,
                        trigger
                      }) => {
  const { title, idKey, nameKey, descriptionKey, formKey } = config;
  const [selectedItems, setSelectedItems] = useState([]);

  const handleToggle = (item) => {
    setSelectedItems((prevSelected) => {
      const isSelected = prevSelected.some((selected) => selected[idKey] === item[idKey]);
      const updatedSelected = isSelected
        ? prevSelected.filter((selected) => selected[idKey] !== item[idKey])
        : [...prevSelected, item];

      if (setValue && formKey) {
        setValue(formKey, updatedSelected);

        trigger(formKey);
      }

      return updatedSelected;
    });
  };

  const filteredItems = items.filter((item) =>
    item[nameKey]?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" gap={3}>

        <Box flex={1} border="1px solid #ccc" borderRadius={2} padding={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={1}>
            <Typography variant="h6">{`Escolha os ${title}`}</Typography>
            <TextField
              label="Pesquisar"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
          <List sx={{ maxHeight: "300px", overflowY: "auto" }}>
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <ListItem
                  key={item[idKey]}
                  onClick={() => handleToggle(item)}
                  sx={{ display: "flex", alignItems: "center", gap: 2 }}
                >
                  <Checkbox
                    checked={selectedItems.some((selected) => selected[idKey] === item[idKey])}
                    sx={{ width: "40px", padding: 0 }}
                  />
                  <ListItemText
                    primary={item[nameKey]}
                    secondary={
                      <Tooltip title={item[descriptionKey] || "Sem descrição"} arrow>
                        <span>
                          {item[descriptionKey] && item[descriptionKey].length > 40
                            ? `${item[descriptionKey].slice(0, 40)}...`
                            : item[descriptionKey] || "Sem descrição"}
                        </span>
                      </Tooltip>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                Nenhum {title.toLowerCase()} encontrado
              </Typography>
            )}
          </List>
        </Box>

        <Box flex={1} border="1px solid #ccc" borderRadius={2} padding={2}>
          <Typography variant="h6">{`${title} Selecionados`}</Typography>
          <List sx={{ maxHeight: "300px", overflowY: "auto" }}>
            {selectedItems.length > 0 ? (
              selectedItems.map((item, index) => (
                <ListItem
                  key={item[idKey] || `item-${index}`}
                  onClick={() => handleToggle(item)}
                >
                  <Checkbox checked sx={{ width: "40px", padding: 0 }} />
                  <ListItemText primary={item[nameKey]} />
                </ListItem>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                Nenhum {title.toLowerCase()} selecionado
              </Typography>
            )}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default ItemSelector;
