import { useNavigate, useParams } from 'react-router-dom';
import { Container } from 'Components/Container';
import React, { useState, useEffect  } from 'react';
import ProgressStepper from '../../../../Components/ProggessStepper';
import { Card } from '../../../../Components/Card';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAuthTenant } from '../../../../store/auth';
import {
  MenuItem, FormControl, Select, InputLabel, Box, Typography,
  Button, CircularProgress, List, ListItem, ListItemText, FormHelperText,
} from '@mui/material';
import { getAreas } from 'services/areas-service';
import { getProcesses } from 'services/processes-service';
import { getSystems } from 'services/systems-service';
import { getAssets } from 'services/assets-service';
import { useQuery } from 'react-query';
import { getGapAnalysisControlsDetails, postGapAnalysisVinculo } from 'services/gap-analysis';
import ItemSelector from '../../../../Components/ItemSelector';

export const GapAnalysisControlsLinkShow = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const tenant = selectedTenant.slug;
  const [searchTerm, setSearchTerm] = useState("");
  const [controlSearchTerm, setControlSearchTerm] = useState("");
  const [items, setItems] = useState([]);
  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState("company");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const {
    isLoading,
    error,
    data: gapAnalysis,
  } = useQuery('getGapAnalysisControlsDetails', async () => {
    const data = await getGapAnalysisControlsDetails(id);
    return data.status === 200 ? data.data : null;
  });

  const { register,
    handleSubmit,
    formState: { errors }, watch, setValue, trigger } = useForm({
    defaultValues: {
      company_id: selectedTenant._id || "",
      type: "companies",
      entity: [],
      controls: [],
    }
  });

  useEffect(() => {
    if (!selectedOption || selectedOption === "companies") {
      setItems(
        selectedTenant
          ? [{ _id: selectedTenant._id, name: selectedTenant.name, description: selectedTenant.description, type: 'companies' }]
          : []
      );
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const selectedObj = options.find((opt) => opt.value === selectedOption);
        if (!selectedObj || !selectedObj.fetchFunction) return;
        const response = await selectedObj.fetchFunction(tenant);
        const itemsWithType = response.data.map((item) => ({
          ...item,
          type: selectedObj.value,
        }));
        setItems(itemsWithType || []);
      } catch (error) {
        setItems([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData()
  }, [selectedOption, tenant, selectedTenant]);


  const options = [
    { label: "Empresa", value: "companies" },
    { label: "Áreas de negócio", value: "areas", fetchFunction: getAreas },
    { label: "Processos", value: "processes", fetchFunction: getProcesses },
    { label: "Sistemas", value: "systems", fetchFunction: getSystems },
    { label: "Ativos", value: "assets", fetchFunction: getAssets },
  ];
  const typeLabels = {
    companies: "Empresa",
    areas: "Áreas de Negócio",
    processes: "Processos",
    systems: "Sistemas",
    assets: "Ativos",
  };


  const submitVinculos = handleSubmit(async (data) => {
    try {
      const formattedData = {
        ...data,
        entity: data.entity.map((item) => ({
          id: item._id,
          type: item.type,
        })),
        controls: data.controls.map((item) => ({
          id: item._id,
          frameworksIds: item.frameworksIds,
        })),
      };
      await postGapAnalysisVinculo(id, formattedData);
      navigate(`/gap-analysis/show/${id}`);

    } catch (error) {
      console.error("Erro ao cadastrar a análise:", error);
    }
  });


  const steps = [
    "Seleção do Vinculo",
    "Seleção de Controles",
    "Resumo & Confirmação"
  ];

  if (isLoading) return <p>Carregando...</p>;
  if (error) return <p>Erro ao carregar os dados.</p>;
  const controls = gapAnalysis.controls;
  const renderStep1 = () => {
    return (
      <Box display="flex" flexDirection="column" gap={3} padding={3}>
        <FormControl fullWidth>
          <InputLabel id="select-label" shrink={true}>Elementos</InputLabel>
          <Select
            labelId="select-label"  notched label="Elementos"
            value={selectedOption}
            variant="outlined"
            onChange={(e) => setSelectedOption(e.target.value)}
         >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>


        {loading && <CircularProgress sx={{ mt: 2 }} />}
        <FormControl fullWidth margin="normal" error={!!errors.entity}>
          <ItemSelector
            config={{
              title: "itens",
              idKey: "_id",
              nameKey: "name",
              descriptionKey: "description",
              formKey: "entity",
            }}
            items={items}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setValue={setValue}
            trigger={trigger}
          />
          {errors.entity && <FormHelperText>{errors.entity.message}</FormHelperText>}

        </FormControl>
      </Box>
    );
  };
  const renderStep2 = () => {
    return (
      <ItemSelector
        config={{
          title: "Controles",
          idKey: "_id",
          nameKey: "scfControl",
          descriptionKey: "scfControlDescription",
          formKey: "controls",
        }}
        items={controls}
        searchTerm={controlSearchTerm}
        setSearchTerm={setControlSearchTerm}
        setValue={setValue}
        trigger={trigger}
      />
    );
  }

  const renderReview = () => {
    const selectedEntity = watch("entity") || [];
    const selectedControls = watch("controls") || [];

    return (
      <Box display="flex" flexDirection="column" gap={3} padding={3}>
        <Typography variant="h5">Resumo da Seleção</Typography>

        {/* Resumo dos Vinculados */}
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="h6">Ativos Selecionados</Typography>
          {selectedEntity.length > 0 ? (
            <List>
              {selectedEntity.map((asset, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                    primary={asset.name || `Ativo sem nome (${asset.id})`}
                    secondary={`Tipo: ${typeLabels[asset.type] || "Não especificado"}`}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography color="textSecondary">Nenhum ativo selecionado.</Typography>
          )}

          <Typography variant="h6">Controles Selecionados</Typography>
          {selectedControls.length > 0 ? (
            <List>
              {selectedControls.map((control, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                    primary={control.scfControl || `Controle sem nome (${control.controlId})`}
                    secondary={control.scfControlDescription || "Sem descrição disponível"}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography color="textSecondary">Nenhum controle selecionado.</Typography>
          )}
        </Box>

        {/* Botão de Salvar */}
        <Button variant="contained" color="primary" onClick={submitVinculos}>
          Salvar Vínculo
        </Button>
      </Box>
    );
  };



  const stepsContent = [
    renderStep1({ watch, errors, register}),
    renderStep2({ watch, errors, register}),
    renderReview({ watch }),
  ];


  return (
    <Container title={"Vincular Controles"}
               breadcrumb={`Assessment / Gap Analysis / ${gapAnalysis.gapAnalysis?.name}`}
               linkPage={`/gap-analysis/show/${gapAnalysis.gapAnalysis?._id || "ID_Nao_Encontrado"}`}
    >


      <Card sx={'mt-4'}>
        <ProgressStepper
          steps={steps}
          activeStep={step}
          onNext={() => setStep((prev) => prev + 1)}
          onBack={() => setStep((prev) => prev - 1)}
          stepsContent={stepsContent} />
      </Card>

    </Container>
  )
}