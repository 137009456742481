export const routePermissions = [
  { path: "/company-profile", permission: "company_profile_r" },
  { path: "/offices", permission: "offices_r" },
  { path: "/offices/show", permission: "offices_r" },
  { path: "/offices/add", permission: "offices_w" },
  { path: "/offices/edit", permission: "offices_w" },
  { path: "/users-groups", permission: "user_group_r" },
  { path: "/users-groups/show", permission: "user_group_r" },
  { path: "/users-groups/add", permission: "user_group_w" },
  { path: "/users-groups/edit", permission: "user_group_w" },
  { path: "/users", permission: "users_r" },
  { path: "/users/show", permission: "users_r" },
  { path: "/users/add", permission: "users_w" },
  { path: "/users/edit", permission: "users_w" },
  { path: "/map", permission: "general_map_r" },
  { path: "/areas", permission: "areas_r" },
  { path: "/areas/show", permission: "areas_r" },
  { path: "/areas/add", permission: "areas_w" },
  { path: "/areas/edit", permission: "areas_w" },
  { path: "/processes", permission: "processes_r" },
  { path: "/processes/show", permission: "processes_r" },
  { path: "/processes/add", permission: "processes_w" },
  { path: "/processes/edit", permission: "processes_w" },
  { path: "/systems", permission: "systems_r" },
  { path: "/systems/show", permission: "systems_r" },
  { path: "/systems/add", permission: "systems_w" },
  { path: "/systems/edit", permission: "systems_w" },
  { path: "/assets", permission: "assets_r" },
  { path: "/assets/show", permission: "assets_r" },
  { path: "/assets/add", permission: "assets_w" },
  { path: "/assets/edit", permission: "assets_w" },
  { path: "/data", permission: "data_r" },
  { path: "/data/show", permission: "data_r" },
  { path: "/data/add", permission: "data_w" },
  { path: "/data/edit", permission: "data_w" },
  { path: "/policies", permission: "policies_r" },
  { path: "/policies/show", permission: "policies_r" },
  { path: "/policies/add", permission: "policies_w" },
  { path: "/policies/edit", permission: "policies_w" },
  { path: "/companies", permission: "company_r" },
  { path: "/companies/show", permission: "company_r" },
  { path: "/companies/add", permission: "company_w" },
  { path: "/companies/edit", permission: "company_w" },
  { path: "/companies-partner", permission: "partner_company_r" },
  { path: "/companies-partner/show", permission: "partner_company_r" },
  { path: "/companies-partner/add", permission: "partner_company_w" },
  { path: "/companies-partner/edit", permission: "partner_company_w" },
  { path: "/gap-analysis", permission: "assessment_gap_analysis_r" },
  { path: "/gap-analysis/show", permission: "assessment_gap_analysis_r" },
  { path: "/gap-analysis/add", permission: "assessment_gap_analysis_w" },
  { path: "/gap-analysis/edit", permission: "assessment_gap_analysis_w" },
  { path: "/reports/ropa", permission: "reports_ropa_r" },
  { path: "/reports/ropa/show", permission: "reports_ropa_r" },
  { path: "/reports/ropa/add", permission: "reports_ropa_w" },
  // { path: "/reports/gap-analysis", permission: "reports_gap_analysis_r" },
  // { path: "/reports/gap-analysis/show", permission: "reports_gap_analysis_r" },
  // { path: "/reports/gap-analysis/add", permission: "reports_gap_analysis_w" },
  { path: "/cookies", permission: "cookies_r" },
  { path: "/cookies/show", permission: "cookies_r" },
  { path: "/cookies/add", permission: "cookies_w" },
  { path: "/cookies/edit", permission: "cookies_w" },
  // { path: "/audit-logs", permission: "audit_logs_r" },
];
/**
 *
        permissão para riscos
        "assessment_risk_r",
        "assessment_risk_w",
        permissão para relatorio de gap analysis
        "reports_gap_analysis_r",
        "reports_gap_analysis_w",
 */