import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  TextField,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import { formatDateTimeToDateTimeBR, formatDateToDateBR, formatDateToDateBRPolish } from '../../utils/date_utils';


export const ExpandableTable = ({
                           rows,
                           columns,
                                  link,
                                  linkExtend,
                           entityColumns,
                           enableSearch = false,
                           enableSorting = false,
                           title = "Tabela",
                                  onClickShow,
                                  onClickDelete
                         }) => {
  const navigate = useNavigate()
  const [expandedRows, setExpandedRows] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [lastClicked, setLastClicked] = useState(null);
  const toggleRow = (id) => {
    setExpandedRows((prev) => {
      const newState = { ...prev };
      if (newState[id]) {
        delete newState[id];
      } else {
        newState[id] = true;
      }
      return newState;
    });

    // Atualiza o último item clicado
    setLastClicked(id);
  };

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(columnId);
  };

  const filteredRows = useMemo(() => {
    if (!searchTerm) return rows;
    return rows.filter((row) =>
      Object.values(row).some((value) =>
        value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [rows, searchTerm]);

  const displayData = (item, row, index) => {
    if (row.type === 'boolean') {
      return item[row.id] ? 'Sim' : 'Não'
    }
    if (row.type === 'actions-add') {
      return (
        <Table
          sx={{
            padding: '0px',
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                '& td': {
                  border: '0px',
                  padding: '0px',
                },
              }}
            >
              <TableCell
                sx={{
                  textAlign: 'center',
                  '& svg': {
                    fill: '#0039C5',
                    '&:nth-of-type(2)': {
                      marginLeft: '4px',
                    },
                    '&:nth-of-type(3)': {
                      marginLeft: '4px',
                      marginRight: '4px',
                    },
                  },
                  '& svg:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {(<NoteAddIcon
                  title="Adicionar Vinculo"
                  onClick={() => {
                    if (onClickShow) {
                      onClickShow(item)
                    } else {
                      navigate(`/${link}/vincular/${item?._id?.toString()}`)
                    }
                  }}
                />)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    }
    if (row.type === 'actions') {
      return (
        <Table
          sx={{
            padding: '0px',
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                '& td': {
                  border: '0px',
                  padding: '0px',
                },
              }}
            >
              <TableCell
                sx={{
                  textAlign: 'center',
                  '& svg': {
                    fill: '#0039C5',
                    '&:nth-of-type(2)': {
                      marginLeft: '4px',
                    },
                    '&:nth-of-type(3)': {
                      marginLeft: '4px',
                      marginRight: '4px',
                    },
                  },
                  '& svg:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {(<VisibilityIcon
                  title="Visualizar"
                  onClick={() => {
                    if (onClickShow) {
                      onClickShow(item)
                    } else {
                      navigate(`/${linkExtend}/show/${item?._id?.toString()}`)
                    }
                  }}
                />)}
                <DeleteOutlineOutlinedIcon
                  title="Remover"
                  onClick={() => onClickDelete(item)} />

              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    }
    if (row.type === 'object') {
      const fields = row.id.split('.')
      return item[fields[0]][fields[1]]
    }
    if (row.type === 'date') {
      return formatDateToDateBRPolish(item[row.id])
    }
    if (row.type === 'datebr') {
      return formatDateToDateBR(item[row.id])
    }
    if (row.type === 'datetime') {
      return formatDateTimeToDateTimeBR(item[row.id], true)
    }
    if (row.type === 'time-expires') {
      if (item[row.id] === -1) {
        return 'Session';
      } else if (item[row.id] < 24) {
        return `${item[row.id]} Hora(s)`
      } else {
        const days = item[row.id] / 24;
        return `${days.toFixed(0)} Dias`
      }
    }
    if (row.type === 'array-object') {
      const fields = row.id.split('.')
      const content = item[fields[0]].findLast(field => field[fields[1]])
      return content ? content[fields[1]] : ''
    }
    if (row.type === 'array-object-datetime') {
      const fields = row.id.split('.')
      const content = item[fields[0]].findLast(field => field[fields[1]])
      return formatDateTimeToDateTimeBR(content?.[fields[1]], true)
    }
    if(row.type === 'text' && item[row.id] === 'null') {
      return ""
    }
    if(row.type === 'type-entity') {
      const typeLabels = {
        areas: "Áreas de Negócio",
        processes: "Processos",
        systems: "Sistemas",
        assets: "Ativos",
        companies: "Empresa",
      };

      return typeLabels[item[row.id]] || item[row.id];
    }
    if(row.type === 'status') {
      const statusOptions = {
        noResponse: "Sem resposta",
        deficient: "Deficiente",
        implemented: "Implementado",
        readyForReview: "Pronto para revisão",
        monitored: "Monitorado",
        notApplicable: "Não Aplicável"
      };
      return statusOptions[item[row.id]] || item[row.id];
    }
    if(row.type === 'maturity-level') {
      const maturityLevels = {
        notPerformed: "Nível 1",
        performedInformally: "Nível 2",
        plannedTracked: "Nível 3",
        wellDefined: "Nível 4",
        quantitativelyControlled: "Nível 5",
        continuouslyImproving: "Nível 6",
      };
      return maturityLevels[item[row.id]] || item[row.id];
    }
    return item[row.id]
  }

  const sortedRows = useMemo(() => {
    if (!orderBy) return filteredRows;
    return [...filteredRows].sort((a, b) => {
      const valA = a[orderBy];
      const valB = b[orderBy];

      if (typeof valA === "string" && typeof valB === "string") {
        return order === "asc" ? valA.localeCompare(valB) : valB.localeCompare(valA);
      }

      if (typeof valA === "number" && typeof valB === "number") {
        return order === "asc" ? valA - valB : valB - valA;
      }

      if (typeof valA === "boolean" && typeof valB === "boolean") {
        return order === "asc" ? Number(valA) - Number(valB) : Number(valB) - Number(valA);
      }

      return order === "asc" ? String(valA).localeCompare(String(valB)) : String(valB).localeCompare(String(valA));
    });
  }, [filteredRows, orderBy, order]);

  return (
    <TableContainer component={Paper}>
      {/* Barra de busca */}
      {enableSearch && (
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <Typography variant="h6" color="primary">{title}</Typography>
          <TextField
            label="Pesquisar"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              borderRadius: "20px",
              "& .MuiOutlinedInput-root": { borderRadius: "20px" },
            }}
          />
        </Box>
      )}

      <Table>
        <TableHead>
          <TableRow>

            {columns.map((column) => (
              <TableCell key={column.id}>
                {enableSorting && column.sortable ? (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                ) : (
                  column.label
                )}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row, rowIndex) => (
            <React.Fragment key={row._id}>
              <TableRow>
                {columns.map((column, colIndex) => (
                  <TableCell key={column.id}>
                    {displayData(row, column, colIndex)}
                  </TableCell>
                ))}
                <TableCell>
                  {row.entities?.length > 0 && (
                    <IconButton size="small" onClick={() => toggleRow(row._id)}>
                      {expandedRows[row._id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>

              {/* Linha oculta com os vínculos (entities) */}
              {expandedRows[row._id] && row.entities?.length > 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} style={{ padding: 0 }}>
                    <Collapse in={expandedRows[row._id]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              {entityColumns.map((entityColumn) => (
                                <TableCell key={entityColumn.id}>{entityColumn.label}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.entities.map((entity, entityIndex) => (
                              <TableRow key={entity._id}>
                                {entityColumns.map((entityColumn) => (
                                  <TableCell key={entityColumn.id}>
                                    {displayData(entity, entityColumn, entityIndex)}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>

      </Table>
    </TableContainer>
  );
};

export default ExpandableTable;
