import React from "react";
import { Stepper, Step, StepLabel, Button, Box } from "@mui/material";

function ProgressStepper({ steps, activeStep, onNext, onBack, stepsContent }) {
  return (
    <Box sx={{ width: "100%", padding: "20px" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Renderiza o conteúdo correspondente à etapa ativa */}
      <Box sx={{ marginTop: "20px", padding: "20px", border: "1px solid #ccc", borderRadius: "5px" }}>
        {stepsContent[activeStep]}
      </Box>

      {/* Botões para navegação */}
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Button disabled={activeStep === 0} onClick={onBack}>
          Voltar
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          sx={{ marginLeft: "10px" }}
          disabled={activeStep === steps.length - 1}
        >
          Próximo
        </Button>
      </Box>
    </Box>
  );
}

export default ProgressStepper;