import { useNavigate, useParams } from 'react-router-dom';
import { Container } from 'Components/Container';
import React, { useState, useEffect  } from 'react';
import { Card } from '../../../../Components/Card';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAuthTenant } from '../../../../store/auth';
import {
  MenuItem, FormControl, Select, InputLabel, Box, Typography,
  Button, CircularProgress, FormHelperText,
} from '@mui/material';
import { getAreas } from 'services/areas-service';
import { getProcesses } from 'services/processes-service';
import { getSystems } from 'services/systems-service';
import { getAssets } from 'services/assets-service';
import { useQuery } from 'react-query';
import { getGapAnalysisControl, postGapAnalysisVinculo } from 'services/gap-analysis';
import ItemSelector from '../../../../Components/ItemSelector';
import { Column, Row } from '../../../../Components/Grid';
import { Title } from '../ControlDetal/styles';

export const GapAnalysisControlLinkShow = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const tenant = selectedTenant.slug;
  const [searchTerm, setSearchTerm] = useState("");
  const [items, setItems] = useState([]);
  const { id, control_id } = useParams();
  const [selectedOption, setSelectedOption] = useState("company");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    isLoading,
    error,
    data: gapAnalysis,
  } = useQuery('getGapAnalysisControl', async () => {
    const data = await getGapAnalysisControl(id, control_id);
    return data.status === 200 ? data.data : null;
  });



  const {
    handleSubmit,
    formState: { errors },  setValue, trigger,  } = useForm({
    defaultValues: {
      company_id: selectedTenant._id || "",
      type: "companies",
      entity: [],
      controls: [],
    }
  });

  useEffect(() => {
    if (!selectedOption || selectedOption === "companies") {
      setItems(
        selectedTenant
          ? [{ _id: selectedTenant._id, name: selectedTenant.name, description: selectedTenant.description, type: 'companies' }]
          : []
      );
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const selectedObj = options.find((opt) => opt.value === selectedOption);

        if (!selectedObj || !selectedObj.fetchFunction) return;
        const response = await selectedObj.fetchFunction(tenant);
        const itemsWithType = response.data
          .map((item) => ({
            ...item,
            type: selectedObj.value,
          }))
          .filter(
            (item) =>
              !gapAnalysis.controlGapAnalysis.some(
                (control) =>
                  control.entity_id === item._id && control.entity_type === item.type
              )
          );
        setItems(itemsWithType || []);
      } catch (error) {
        setItems([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedOption, tenant, selectedTenant]);
  const getSolutionKey = (size) => {
    if (size >= 1 && size <= 9) return "solutionsMicroSmall";
    if (size >= 10 && size <= 49) return "solutionsSmall";
    if (size >= 50 && size <= 249) return "solutionsMedium";
    if (size >= 250 && size <= 999) return "solutionsLarge";
    if (size >= 1000) return "solutionsEnterprise";
    return null;
  };
  const employeeSize = parseFloat(selectedTenant.size);
  const solutionKey = getSolutionKey(employeeSize);

  const options = [
    { label: "Empresa", value: "companies" },
    { label: "Áreas de negócio", value: "areas", fetchFunction: getAreas },
    { label: "Processos", value: "processes", fetchFunction: getProcesses },
    { label: "Sistemas", value: "systems", fetchFunction: getSystems },
    { label: "Ativos", value: "assets", fetchFunction: getAssets },
  ];

  if (isLoading) return <p>Carregando...</p>;
  if (error) return <p>Erro ao carregar os dados.</p>;

  const submitVinculos = handleSubmit(async (data) => {
    try {
      const formattedData = {
        ...data,
        entity: data.entity.map((item) => ({
          id: item._id,
          type: item.type,
        })),
        controls: [{
          id: gapAnalysis.control._id,
          frameworksIds: gapAnalysis.frameworks.map((framework) => framework._id),
        }],
      };
      await postGapAnalysisVinculo(id, formattedData);
      navigate(`/gap-analysis/show/${id}`);
    } catch (error) {
      console.error("Erro ao cadastrar a análise:", error);
    }
  });




  return (
    <Container
      title={`Vincular Controle - ${gapAnalysis.control.scfControl}`}
      breadcrumb={`Assessment / Gap Analysis / ${gapAnalysis.gapAnalysis?.name}`}
      linkPage={`/gap-analysis/show/${gapAnalysis.gapAnalysis?._id || "ID_Nao_Encontrado"}`}


    >
      <Row className="px-2 mt-3 mb-3">
        <Column xs={12}>
          <Card>
            <Title className="m-0 mb-3">Detalhes do Controle</Title>
            <div style={{ display: "flex", gap: "16px", marginBottom: "16px" }}>
              <Typography><strong>Domínio:</strong> {gapAnalysis.control.scfDomain}</Typography>
              <Typography><strong>Agrupamento de Funções:</strong> <span>{gapAnalysis.control.nistCsfFunctionGrouping}</span></Typography>
              <Typography><strong>Aplicabilidade:</strong> {gapAnalysis.control.applicabilityStatus} </Typography>
              <Typography><strong>Peso de Controle:</strong> {gapAnalysis.control.controlWeighting} </Typography>
            </div>
            <Typography>
              <strong>Descrição do Controle:</strong> {gapAnalysis.control.scfControlDescription}
            </Typography>
            <Typography style={{ marginTop: "16px" }}>
              <strong>Requisitos:</strong>
            </Typography>
            {gapAnalysis.frameworks.map((framework) => (
              <Typography key={framework._id}>
                {framework.name}: {gapAnalysis.control.frameworks[framework._id]?.join(", ")}
              </Typography>
            ))}

            <Typography style={{ marginTop: "16px" }}>
              <strong>Métodos para cumprir:</strong>
              <Typography style={{ whiteSpace: "pre-line" }}>
                {gapAnalysis.control[solutionKey]}
              </Typography>
            </Typography>

          </Card>
        </Column>
      </Row>
      <Row className="px-2 mt-3 mb-3">
        <Column xs={12}>
          <Card sx={'mt-4'}>
            <Title className="m-0 mb-3">Vincular Elementos</Title>
            <Box display="flex" flexDirection="column" gap={3} padding={2}>
              <FormControl fullWidth>
                <InputLabel id="select-label">Elementos</InputLabel>
                <Select
                  labelId="select-label"  notched label="Elementos"
                  value={selectedOption}
                  variant="outlined"
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {loading && <CircularProgress sx={{ mt: 1 }} />}
              <FormControl fullWidth margin="normal" error={!!errors.entity}>
                <ItemSelector
                  config={{
                    title: "itens",
                    idKey: "_id",
                    nameKey: "name",
                    descriptionKey: "description",
                    formKey: "entity",
                  }}
                  items={items}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  setValue={setValue}
                  trigger={trigger}
                />
                {errors.entity && <FormHelperText>{errors.entity.message}</FormHelperText>}

              </FormControl>
              <Button variant="contained" color="primary" onClick={submitVinculos}>
                Salvar Vínculo
              </Button>
            </Box>
          </Card>
        </Column>
      </Row>
    </Container>
  )
}