import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import { Box, FormControl, FormHelperText, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useQueries } from 'react-query';
import { getFrameworks, getControlsGrouped, postGapAnalysis } from 'services/gap-analysis';
import { Card } from 'Components/Card';
import ProgressStepper from 'Components/ProggessStepper';
import { Container } from 'Components/Container';
import React, { useEffect, useState } from 'react';
import FrameworkSelector from 'Components/FrameworkSelector';
import DomainControlsList from 'Components/DomainControlsList';
import { useNavigate } from 'react-router-dom';

export const GapAnalysisForm = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const [selectedFrameworks, setSelectedFrameworks] = useState([]);
  const [controls, setControls] = useState([]);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
    unregister,
  } = useForm({
    defaultValues: {
      company_id: selectedTenant._id || '',
      asset_id: '',
      name: '',
      description: '',
      type: 'company',
      frameworks: [],
    },
  });
  useEffect(() => {
    if (selectedTenant) {
      setValue('company_id', selectedTenant._id);
    }
  }, [selectedTenant, setValue]);

  const results = useQueries([
    {
      queryKey: ['frameworks'],
      queryFn: getFrameworks,
    },
  ]);

  const frameworksData = results[0].data?.data || [];

  // 📌 Busca controles dinamicamente com base nos frameworks selecionados
  useEffect(() => {
    const fetchControlsGrouped = async frameworksIds => {
      try {
        if (frameworksIds.length === 0) {
          setControls([]); // Se não houver frameworks selecionados, limpa os controles
          return;
        }
        const result = await getControlsGrouped(frameworksIds);
        if (result.status === 200) {
          setControls(result.data);
        } else {
          console.error('Erro ao buscar controles');
        }
      } catch (error) {
        console.error('Erro ao buscar controles agrupados:', error);
      }
    };

    fetchControlsGrouped(selectedFrameworks.map(fw => fw._id));
  }, [selectedFrameworks]);

  useEffect(() => {
    if (step === 1) {
      register('frameworks', {
        validate: value => (Array.isArray(value) && value.length > 0) || 'Selecione pelo menos um framework',
      });

      if (!watch('frameworks') || watch('frameworks').length === 0) {
        setValue(
          'frameworks',
          selectedFrameworks.map(fw => fw._id),
        );
      }
    } else if (step === 0 && selectedFrameworks.length === 0) {
      unregister('frameworks');
    }
  }, [register, unregister, step, setValue, watch, selectedFrameworks]);

  // 📌 Seleciona/Deseleciona Frameworks
  const handleToggle = framework => {
    setSelectedFrameworks(prevSelected => {
      const isSelected = prevSelected.some(item => item._id === framework._id);
      const updatedSelected = isSelected
        ? prevSelected.filter(item => item._id !== framework._id)
        : [...prevSelected, framework];

      setValue(
        'frameworks',
        updatedSelected.map(fw => fw._id),
      );
      trigger('frameworks');

      return updatedSelected;
    });
  };

  // 📌 Envia os dados para cadastrar a Análise
  const submitGapAnalysis = handleSubmit(async data => {
    try {
      const payload = {
        ...data,
        framework_ids: selectedFrameworks.map(fw => fw._id),
      };
      delete payload.frameworks;
      const response = await postGapAnalysis(payload);

      const analysisId = response.data?._id || response.data?.id;
      if (response.status === 201 || response.status === 200) {
        if (analysisId) {
          navigate(`/gap-analysis/show/${analysisId}`);
        } else {

        }

      } else {
      }
    } catch (error) {
      alert('Ocorreu um erro ao cadastrar a análise. Verifique os dados e tente novamente.');
    }
  });

  const renderStep1 = () => {
    return (
      <Box component="form">
        <FormControl fullWidth margin="normal" error={!!errors.name}>
          <TextField label="Nome" {...register('name', { required: 'Nome é obrigatório' })} />
          {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField label="Descrição" {...register('description')} multiline rows={3} />
        </FormControl>
      </Box>
    );
  };
  const renderStep2 = () => (
    <FormControl fullWidth margin="normal" error={!!errors.frameworks}>
      <FrameworkSelector
        frameworks={frameworksData}
        selectedFrameworks={selectedFrameworks}
        handleToggle={handleToggle}
      />
      {errors.frameworks && <FormHelperText>{errors.frameworks.message}</FormHelperText>}
    </FormControl>
  );

  const renderStep3 = () => <DomainControlsList data={controls} />;

  const renderReview = () => {
    return (
      <Box>
        <h3>Resumo da Análise</h3>
        <p>
          <strong>Nome:</strong> {watch('name') || 'Não informado'}
        </p>
        <p>
          <strong>Descrição:</strong> {watch('description') || 'Não informado'}
        </p>

        <p>
          <strong>Frameworks Selecionados:</strong>
        </p>
        {selectedFrameworks.length > 0 ? (
          <ul>
            {selectedFrameworks.map(fw => (
              <li key={fw._id}>
                {fw.sourceIdentifier} ({fw.controlCount} controles)
              </li>
            ))}
          </ul>
        ) : (
          <p>Nenhum framework selecionado</p>
        )}

        <Button variant="contained" color="primary" onClick={submitGapAnalysis}>
          Salvar Análise
        </Button>
      </Box>
    );
  };
  const stepsContent = [
    renderStep1({ watch, errors, register }),
    renderStep2({ frameworksData, selectedFrameworks, handleToggle }),
    renderStep3(),
    renderReview({ watch, selectedFrameworks, submitGapAnalysis }),
  ];
  const steps = ['Definição da Análise', 'Seleção de Frameworks', 'Revisão de Controles', 'Resumo & Confirmação'];

  return (
    <Container title="Adicionar Gap Analysis" breadcrumb="Assessment / Gap Analysis" linkPage="/gap-analysis">
      <Card sx={'mt-4'}>
        <ProgressStepper
          steps={steps}
          activeStep={step}
          onNext={async () => {
            let isValid = await trigger();
            if (step === 1) {
              const frameworksValid = await trigger('frameworks');
              isValid = isValid && frameworksValid; // Só avança se os frameworks estiverem válidos
            }
            if (isValid) {
              setStep(prev => prev + 1);
            }
          }}
          onBack={() => setStep(prev => prev - 1)}
          stepsContent={stepsContent}
        />
      </Card>
    </Container>
  );
};
