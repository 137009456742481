import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { deleteCookie, getCookies } from 'services/cookies-service';
import { tableHeader } from './table-header.js';
import { useAuthTenant } from 'store/auth';

import { DefaultTable } from 'Components/DefaultTable';
import { Container } from '../../Components/Container';
import { Information } from 'Components/Information';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';
import { PageLoaderList } from 'Components/PageLoader/List';
import { NoData } from 'Components/NoData';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
// import { HttpStatus } from 'utils/http_status_utils.js';
import { Column, Row } from 'Components/Grid/index.js';

export const Cookies = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const SwalReact = withReactContent(Swal);
  const [open, setOpen] = useState(false);
  const [reFetch, setRefetch] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const navigate = useNavigate();
  const {
    isLoading,
    error,
    data: cookie,
  } = useQuery(['getCookies', reFetch], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getCookies(selectedTenant.slug);
    if (result?.status === 200) {
      return result?.data;
    }
    return {
      totalizer: {
        opt_ins: 0,
        opt_out: 0,
        unique_user: 0,
      },
      disclaimers: [],
    };
  });

  const onDelete = item => {
    setItemToDelete(item);
    setOpen(true);
  };

  const onClose = () => {
    setItemToDelete(null);
    setOpen(false);
  };

  const mutationDelete = useMutation(
    id => {
      return deleteCookie(id);
    },
    {
      onError: (error, _variables, _context) => {
        const errorMessage = error.response.data.message || error.message;
        // An error happened!
        SwalReact.fire({
          title: 'Erro!',
          text: errorMessage,
          icon: 'error',
        });
      },
      onSuccess: () => {
        onClose();
        setRefetch(!reFetch);
        SwalReact.fire({
          title: 'Sucesso!',
          text: 'Cookie removido com sucesso',
          icon: 'success',
        });
      },
    },
  );

  const onConfirm = () => {
    mutationDelete.mutate(itemToDelete?.id);
  };

  return (
    <Container
      title="Gestão de Cookies e Consentimento"
      breadcrumb="Adequações / Gestão de Cookies e Consentimento"
      showButtonAdd
      linkPage="/cookies"
    >
      <Information
        title="Gestão de Cookies e Consentimento"
        description={
          <>
            O Cookie serve para cadastrar nos sites a exibição de uma coleta de dados com requisição de permissão para
            isso
          </>
        }
        style={{
          marginTop: '16px',
        }}
      />
      {isLoading && <PageLoaderList></PageLoaderList>}
      {error && <div>Ocorreu um erro ...</div>}
      {!isLoading && !error && cookie.disclaimers?.length === 0 && (
        <NoData description={'Não há cookies para exibir'} />
      )}
      {!isLoading && !error && cookie.disclaimers?.length > 0 && (
        <div className="container">
          {/**
           * Aqui vai os totalizadores
           */}
          <Row className="mb-3">
            <Column>
              <Row justifyContent="center">
                <div className="mr-2">
                  Visitantes únicos: <span className="font-weight-bold">{cookie.totalizer.unique_users}</span>
                </div>
                <div className="mr-2">
                  Opt-in: <span className="font-weight-bold">{cookie.totalizer.opt_ins}</span>
                </div>
                <div>
                  Opt-out: <span className="font-weight-bold">{cookie.totalizer.opt_out}</span>
                </div>
              </Row>
            </Column>
          </Row>
          <DefaultTable
            rows={tableHeader}
            cells={cookie.disclaimers}
            onClickDelete={onDelete}
            link="cookies"
            onClickEdit={item => {
              navigate(`/cookies/edit/${item.id}`);
            }}
            onClickShow={item => {
              navigate(`/cookies/show/${item.id}`);
            }}
            onClick={() => {}}
          />
          <ModalConfirmDelete
            description={
              <>
                Tem certeza que deseja excluir <strong>{` ${itemToDelete?.url} `}</strong> do vinculo de cookies?
              </>
            }
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            loading={mutationDelete.isLoading}
          />
        </div>
      )}
    </Container>
  );
};
