import React, { useState } from "react";
import { Box, Checkbox, List, ListItem, ListItemText, Typography, TextField } from "@mui/material";

function FrameworkSelector({ frameworks, selectedFrameworks, handleToggle }) {
  const [searchTerm, setSearchTerm] = useState("");

  // Filtra frameworks com base na pesquisa
  const filteredFrameworks = frameworks.filter((framework) =>
    framework.sourceIdentifier.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box display="flex" gap={3} padding={3}>
      <Box flex={1} border="1px solid #ccc" borderRadius={2} padding={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={1}>
          <Typography variant="h6">Escolha os Frameworks</Typography>
          <TextField
            label="Pesquisar"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <List sx={{ maxHeight: "300px", overflowY: "auto" }}>
          {filteredFrameworks.length > 0 ? (
            filteredFrameworks.map((framework) => (
              <ListItem
                key={framework._id}
                button
                onClick={() => handleToggle(framework)}
                sx={{ display: "flex", alignItems: "center", gap: 2 }}
              >
                <Checkbox
                  checked={selectedFrameworks.some((item) => item._id === framework._id)}
                  sx={{ width: "40px", padding: 0 }}
                />
                <ListItemText
                  primary={framework.sourceIdentifier}
                  secondary={`${framework.controlCount} controls`}
                />
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              Nenhum framework encontrado
            </Typography>
          )}
        </List>
      </Box>

      {/* Lista de frameworks selecionados */}
      <Box flex={1} border="1px solid #ccc" borderRadius={2} padding={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">
            Frameworks Selecionados
          </Typography>
          <Typography variant="body1" color="primary">
            {selectedFrameworks.reduce((total, framework) => total + framework.controlCount, 0)} controles
          </Typography>
        </Box>
        <List sx={{ maxHeight: "300px", overflowY: "auto" }}>
          {selectedFrameworks.length > 0 ? (
            selectedFrameworks.map((framework) => (
              <ListItem key={framework._id} button onClick={() => handleToggle(framework)}>
                <Checkbox checked sx={{ width: "40px", padding: 0 }} />
                <ListItemText
                  primary={framework.sourceIdentifier}
                  secondary={`${framework.controlCount} controls`}
                />
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              Nenhum framework selecionado
            </Typography>
          )}
        </List>
      </Box>
    </Box>
  );
}

export default FrameworkSelector;
