import { useState } from 'react';
import { useQuery, useInfiniteQuery, useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';

// Components
import { Alert, Box, Button, Card, Snackbar, Stack, Switch, Tab, Tabs } from '@mui/material';
import { Container } from 'Components/Container';
import { Column, Row } from 'Components/Grid';
import {
  CardStyled,
  ColumnEdit,
  ContainerIcon,
  LinkEdit,
  RowExhibition,
  SubTitleModalDetail,
  TitleModalDetail,
} from './styles';
import { PageLoaderShow } from 'Components/PageLoader/Show';
import { DefaultTable } from 'Components/DefaultTable';

// Icons
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ReactComponent as CopyIcon } from '../../../assets/copy_icon.svg';
import { ReactComponent as SecurityIcon } from '../../../assets/security.svg';
import { ReactComponent as OpenLinkIcon } from '../../../assets/open_link.svg';
import DotsLoader from 'Components/DotsLoader';

// Services
import {
  checkDisclaimerInstall,
  createDisclaimerScan,
  getCookieById,
  getCookieConsentByTenant,
} from 'services/cookies-service';
import { tableHeaderCategory, tableHeaderConsent } from './table-header';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { ButtonForm } from 'Components/Form/styles';

const TabPanel = props => {
  const { children, value, index, id, ariaLabel, boxPadding, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={id} aria-labelledby={ariaLabel} {...other}>
      {value === index && <Box sx={{ p: boxPadding ?? 3 }}>{children}</Box>}
    </div>
  );
};

export const CookiesShow = () => {
  const navigate = useNavigate();
  const selectedTenant = useSelector(useAuthTenant);
  const { id } = useParams();
  const SwalReact = withReactContent(Swal);
  const [tabPosition, setTabPosition] = useState(0);
  const [showCopyCode, setShowCopyCode] = useState(false);
  const [showGtm, setShowGtm] = useState(false);
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const {
    isLoading,
    error,
    data: cookie,
  } = useQuery('getCookieById', async () => {
    const dataCookie = await getCookieById(id);
    return dataCookie.status === 200 ? dataCookie.data : null;
  });

  const {
    data: cookieConsent,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['getCookieConsentByTenant'],
    queryFn: async ({ pageParam }) => {
      if (selectedTenant.slug === undefined) {
        return {
          consents: [],
          lastId: null,
        };
      }
      const dataCookieConsent = await getCookieConsentByTenant(selectedTenant.slug, pageParam);
      if (dataCookieConsent.status === 200) {
        return {
          consents: dataCookieConsent.data.consents,
          lastIds: dataCookieConsent.data.lastIds,
        };
      }
      return {
        consents: [],
        lastIds: null,
      };
    },
    getNextPageParam: lastPage => lastPage.lastIds || null,
  });

  const onClickEdit = () => {
    navigate('/cookies/edit/' + id);
  };

  const getPositionBanner = position => {
    switch (position) {
      case 'left':
        return 'Canto Inferior Esquerdo';
      default:
        return 'Canto Inferior Direito';
    }
  };

  const copyCode = async () => {
    try {
      await navigator.clipboard.writeText(`
        <meta name="adopt-website-id" content="${id}" />
        <script
          src="//tag.goadopt.io/injector.js?website_code=${id}"
          class="adopt-injector"
        ></script>
        `);
      setShowCopySuccess(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  const convertExpires = expires => {
    if (expires === -1) {
      return 'Session';
    } else if (Number((expires / 1000).toFixed(0)) < 60) {
      return `${Number((expires / 1000).toFixed(0))} Segundo(s)`;
    } else if (Number((expires / 60000).toFixed(0)) < 60) {
      return `${Number((expires / 60000).toFixed(0))} Minuto(s)`;
    } else if (Number((expires / 3600000).toFixed(0)) < 24) {
      return `${Number((expires / 3600000).toFixed(0))} Hora(s)`;
    } else {
      const days = Number((expires / 3600000).toFixed(0)) / 24;
      return `${days.toFixed(0)} Dia(s)`;
    }
  };

  const CookiesDetail = ({ name, cookies }) => (
    <>
      <Row className="mb-3">
        <Column xs={1} flex className="justify-content-center align-items-start px-0">
          <div
            className="swal2-icon swal2-info swal2-icon-show"
            style={{
              display: 'flex',
              height: '1.5rem',
              width: '1.5rem',
              margin: '0',
            }}
          >
            <div className="swal2-icon-content" style={{ fontSize: '1.5rem' }}>
              i
            </div>
          </div>
        </Column>
        <Column>
          <TitleModalDetail>Detalhes da tag {name}</TitleModalDetail>
          <SubTitleModalDetail>Listagem dos cookies que foram encontrados dentro da tag</SubTitleModalDetail>
        </Column>
      </Row>
      <div style={{ height: '165px', overflowY: 'auto' }}>
        {cookies.map((cookie, index) => (
          <CardStyled className="mb-2" key={index}>
            <RowExhibition>
              <Column className="font-weight-bold" xs={4}>
                Nome:
              </Column>
              <Column>{cookie?.name}</Column>
            </RowExhibition>
            <RowExhibition>
              <Column className="font-weight-bold" xs={4}>
                Expiração:
              </Column>
              <Column>{convertExpires(cookie?.expires)}</Column>
            </RowExhibition>
            <RowExhibition>
              <Column className="font-weight-bold" xs={4}>
                Região:
              </Column>
              <Column>{cookie?.region}</Column>
            </RowExhibition>
            <RowExhibition>
              <Column className="font-weight-bold" xs={4}>
                Bloqueado:
              </Column>
              <Column>{cookie?.auto_block ? 'Bloqueado' : 'Não bloqueado'}</Column>
            </RowExhibition>
          </CardStyled>
        ))}
      </div>
    </>
  );

  const showDisclaimerDetailModal = item => {
    const contentHtml = <CookiesDetail name={item.name} cookies={item.cookies} />;
    console.log('conteudo do content html: ', contentHtml);
    SwalReact.fire({
      // title: 'Detalhe do consentimento!',
      html: contentHtml,
      // icon: 'info',
    });
  };

  const mutationMonthlySchedule = useMutation(
    disclaimerId => {
      // envia id do disclaimer para validação no backend
      if (disclaimerId == null) throw new Error('Aviso não identificado verifique se o identificador esta correto!');
      return createDisclaimerScan(disclaimerId);
    },
    {
      onError: error => {
        const errorMessage = error.response.data.message || error.message;
        // An error happened!
        console.log('conteudo do erro: ', errorMessage);
      },
      onSuccess: data => {
        console.log('conteudo do sucesso: ', data);
      },
    },
  );

  const mutationCheckInstall = useMutation(
    disclaimerId => {
      // envia id do disclaimer para validação no backend
      if (disclaimerId == null) throw new Error('Aviso não identificado verifique se o identificador esta correto!');
      return checkDisclaimerInstall(disclaimerId);
    },
    {
      onError: error => {
        const errorMessage = error.response.data.message || error.message;
        // An error happened!
        console.log('conteudo do erro: ', errorMessage);
      },
      onSuccess: data => {
        console.log('conteudo do sucesso: ', data);
      },
    },
  );

  return (
    <>
      {!isLoading && !error && (
        <Container title={`${cookie?.domain}`} breadcrumb={`Adequações / ${cookie?.domain}`} linkPage="/cookies">
          <Box sx={{ width: '100%' }} className="container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabPosition}
                onChange={(_, newValue) => setTabPosition(newValue)}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Dashboard"
                  id="tab-dashboard"
                  aria-controls="tabpanel-integration"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Posicinamento e Formato"
                  id="tab-details"
                  aria-controls="tabpanel-details"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Categorização"
                  id="tab-category"
                  aria-controls="tabpanel-category"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Consentimento"
                  id="tab-consent"
                  aria-controls="tabpanel-consent"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
              </Tabs>
            </Box>
            <TabPanel value={tabPosition} index={0} id="tabpanel-dashboard" ariaLabel="tab-dashboard">
              <Row>
                <Column xs={12} md={6}>
                  <Card sx={{ padding: '8px', textAlign: 'center', boxShadow: 'none', border: '1px solid #ccc' }}>
                    <span style={{ fontSize: '14px', color: '#6b7280' }}>Status Integração</span>
                    <br />
                    <div
                      style={{
                        height: '35px',
                        width: '35px',
                        borderRadius: '50%',
                        backgroundColor: cookie.disclaimerStatusAtivo ? 'green' : 'red',
                        marginInline: 'auto',
                        marginBlockStart: '8px',
                      }}
                    >
                      &nbsp;
                    </div>
                  </Card>
                </Column>
                <Column xs={12} md={6}>
                  <Card sx={{ padding: '8px', textAlign: 'center', boxShadow: 'none', border: '1px solid #ccc' }}>
                    <span style={{ fontSize: '14px', color: '#6b7280' }}>Total de Visitas</span>
                    <br />
                    <span style={{ fontSize: '32px', fontWeight: 'bold', marginBlockStart: '8px' }}>
                      {cookie.totalVisitors}
                    </span>
                  </Card>
                </Column>
              </Row>
              <Row>
                <Column xs={12}>
                  <Card sx={{ padding: '16px', marginBlockStart: '16px', boxShadow: 'none', border: '1px solid #ccc' }}>
                    <Row>
                      <Column>
                        <span style={{ fontSize: '20px', color: '#4b90fe' }}>Código do banner de cookies</span>
                      </Column>
                    </Row>
                    <Row>
                      <Column>
                        <Card
                          sx={{
                            padding: '16px',
                            marginBlockStart: '16px',
                            backgroundColor: '#fffeFe',
                            boxShadow: 'none',
                            border: '1px solid #ccc',
                          }}
                        >
                          <Row className="mb-3">
                            <Column xs={9} style={{ fontSize: '20px', fontWeight: '500' }}>
                              Copiar código
                            </Column>
                            <Column style={{ display: 'flex', justifyContent: 'end' }}>
                              <ContainerIcon
                                onClick={() => {
                                  setShowGtm(false);
                                  setShowCopyCode(!showCopyCode);
                                }}
                                active={showCopyCode}
                              >
                                <CopyIcon width="44px" height="44px" style={{ padding: '8px' }} />
                              </ContainerIcon>
                            </Column>
                          </Row>
                          <Row className="mb-3">
                            <Column xs={12}>
                              <p className="mt-0 mb-4">
                                Você só tem que colar o código no inicio da tag {'<HEAD/>'}
                                do conteúdo HTML do seu site.
                              </p>
                            </Column>
                          </Row>
                        </Card>
                      </Column>
                      <Column>
                        <Card
                          sx={{
                            padding: '16px',
                            marginBlockStart: '16px',
                            backgroundColor: '#fffeFe',
                            boxShadow: 'none',
                            border: '1px solid #ccc',
                          }}
                        >
                          <Row>
                            <Column xs={9}>
                              <span style={{ fontSize: '20px', fontWeight: '500' }}>Google Tag Manager</span>
                              <br />
                              <span style={{ fontSize: '16px', fontWeight: '400', color: '#34d399' }}>
                                (Recomendado)
                              </span>
                            </Column>
                            <Column style={{ display: 'flex', justifyContent: 'end' }}>
                              <ContainerIcon
                                onClick={() => {
                                  setShowCopyCode(false);
                                  setShowGtm(!showGtm);
                                }}
                                active={showGtm}
                              >
                                <SecurityIcon width="44px" height="44px" style={{ padding: '8px' }} />
                              </ContainerIcon>
                            </Column>
                          </Row>
                          <Row>
                            <Column xs={12}>
                              <p>
                                Gerenciamos seus cookies, mas o GTM pode chegar primeiro, com esta opção basta instalar
                                nosso template.
                              </p>
                            </Column>
                          </Row>
                        </Card>
                      </Column>
                    </Row>
                    {showCopyCode && (
                      <Row>
                        <Column xs={12} flex style={{ alignItems: 'center', marginBlock: '16px' }}>
                          <div
                            style={{
                              width: '100%',
                              height: '1px',
                              backgroundColor: '#d1d5db',
                              border: '1px solid #e5e7eb',
                            }}
                          ></div>
                          <span
                            style={{ fontSize: '14px', fontWeight: '400', marginInline: '12px', textWrap: 'nowrap' }}
                          >
                            Copiar código
                          </span>
                          <div
                            style={{
                              width: '100%',
                              height: '1px',
                              backgroundColor: '#d1d5db',
                              border: '1px solid #e5e7eb',
                            }}
                          ></div>
                        </Column>
                      </Row>
                    )}
                    {showCopyCode && (
                      <Row>
                        <Column xs={12}>
                          <Card
                            sx={{
                              backgroundColor: '#f9fafb',
                              padding: '12px 24px',
                              boxShadow: 'none',
                              border: '1px solid #ccc',
                            }}
                          >
                            <Row>
                              <Column xs={10}>
                                <span id="scriptToCopy" style={{ fontSize: '14px' }}>
                                  {`<meta name="adopt-website-id" content="${id}" />`}
                                  <br />
                                  {`<script
                                    src="//tag.goadopt.io/injector.js?website_code=${id}"
                                    className="adopt-injector"
                                  ></script>`}
                                </span>
                              </Column>
                              <Column xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button
                                  variant="contained"
                                  startIcon={<CopyIcon width="22px" height="24px" style={{ padding: '2px' }} />}
                                  size="small"
                                  onClick={() => {
                                    copyCode();
                                  }}
                                >
                                  Copiar
                                </Button>
                              </Column>
                            </Row>
                          </Card>
                        </Column>
                      </Row>
                    )}
                    {showGtm && (
                      <Row>
                        <Column xs={12} flex style={{ alignItems: 'center', marginBlock: '16px' }}>
                          <div
                            style={{
                              width: '100%',
                              height: '1px',
                              backgroundColor: '#d1d5db',
                              border: '1px solid #e5e7eb',
                            }}
                          ></div>
                          <span
                            style={{ fontSize: '14px', fontWeight: '400', marginInline: '12px', textWrap: 'nowrap' }}
                          >
                            Google Tag Manager
                          </span>
                          <div
                            style={{
                              width: '100%',
                              height: '1px',
                              backgroundColor: '#d1d5db',
                              border: '1px solid #e5e7eb',
                            }}
                          ></div>
                        </Column>
                      </Row>
                    )}
                    {showGtm && (
                      <Row>
                        <Column xs={12}>
                          <Card
                            sx={{
                              backgroundColor: '#f9fafb',
                              padding: '12px 24px',
                              boxShadow: 'none',
                              border: '1px solid #ccc',
                            }}
                          >
                            <Row>
                              <Column xs={11}>
                                <span style={{ fontSize: '14px' }}>
                                  Siga o passo a passo incluso na página do&nbsp;
                                  <a
                                    style={{ textDecoration: 'none', color: '#1976d2' }}
                                    href="https://support.vofficer.io/hc/pt-br/articles/23322149914260-Integração-do-Banner-de-Cookies-com-o-Google-Tag-Manager"
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    link a seguir
                                  </a>
                                  .
                                </span>
                              </Column>
                              <Column xs={1} className="pr-0" style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button
                                  variant="link"
                                  startIcon={<OpenLinkIcon width="22px" height="24px" style={{ padding: '2px' }} />}
                                  size="small"
                                  sx={{ padding: '6px', minWidth: '32px' }}
                                  href="https://support.vofficer.io/hc/pt-br/articles/23322149914260-Integração-do-Banner-de-Cookies-com-o-Google-Tag-Manager"
                                  rel="noreferrer"
                                  target="_blank"
                                ></Button>
                              </Column>
                            </Row>
                          </Card>
                        </Column>
                      </Row>
                    )}
                  </Card>
                </Column>
              </Row>
              {cookie.disclaimerStatusAtivo && (
                <Row>
                  <Column xs={12}>
                    <Card
                      sx={{
                        padding: '16px',
                        marginBlockStart: '16px',
                        boxShadow: 'none',
                        border: '1px solid #ccc',
                      }}
                    >
                      <Row className="mb-3">
                        <Column>
                          <span style={{ fontSize: '20px', color: '#4b90fe' }}>Configuração do Escaneamento</span>
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          Realizar escaneamento mensal:
                          <Switch
                            defaultChecked={cookie.disclaimerMonthlySchedule}
                            onChange={() => {
                              mutationMonthlySchedule.mutate(id);
                            }}
                            disabled={mutationMonthlySchedule.isLoading || cookie.disclaimerMonthlySchedule}
                            sx={{
                              width: '50px',
                              marginLeft: '8px',
                              '.Mui-checked': {
                                transform: 'translateX(29px) !important',
                                width: '20px',
                                height: '20px',
                                padding: '0',
                                top: '8px',
                              },
                              '.MuiSwitch-switchBase:not(.Mui-checked)': {
                                width: '20px',
                                height: '20px',
                                padding: '0',
                                transform: 'translateX(0px) !important',
                                // left: '0px',
                                top: '8px',
                              },
                            }}
                          />
                        </Column>
                      </Row>
                    </Card>
                  </Column>
                </Row>
              )}
              {!cookie.disclaimerStatusAtivo && (
                <Row>
                  <Column xs={12}>
                    <Card
                      sx={{
                        padding: '16px',
                        marginBlockStart: '16px',
                        boxShadow: 'none',
                        border: '1px solid #ccc',
                      }}
                    >
                      <Row className="mb-3">
                        <Column>
                          <span style={{ fontSize: '20px', color: '#4b90fe' }}>Configuração da Instalação</span>
                        </Column>
                      </Row>
                      <Row>
                        <Column flex justifyContent="end">
                          <ButtonForm
                            variant="button"
                            label={mutationCheckInstall.isLoading ? <DotsLoader /> : 'Verificar instalação'}
                            onClick={() => {
                              mutationCheckInstall.mutate(id);
                            }}
                            disabled={mutationCheckInstall.isLoading}
                          />
                        </Column>
                      </Row>
                    </Card>
                  </Column>
                </Row>
              )}
            </TabPanel>
            <TabPanel value={tabPosition} index={1} id="tabpanel-details" ariaLabel="tab-details">
              <RowExhibition>
                <Column xs={3}>Site</Column>
                <Column>{cookie.domain}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Cor Fundo</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {cookie.background_color}
                  <div
                    style={{
                      backgroundColor: cookie.background_color,
                      width: '30px',
                      height: '15px',
                      display: 'inline-flex',
                      marginInlineStart: '8px',
                      border: '1px solid #ccc',
                    }}
                  >
                    &nbsp;
                  </div>
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Cor Princial</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {cookie.primary_color}
                  <div
                    style={{
                      backgroundColor: cookie.primary_color,
                      width: '30px',
                      height: '15px',
                      display: 'inline-flex',
                      marginInlineStart: '8px',
                      border: '1px solid #ccc',
                    }}
                  >
                    &nbsp;
                  </div>
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Cor Texto</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {cookie.text_color}
                  <div
                    style={{
                      backgroundColor: cookie.text_color,
                      width: '30px',
                      height: '15px',
                      display: 'inline-flex',
                      marginInlineStart: '8px',
                      border: '1px solid #ccc',
                    }}
                  >
                    &nbsp;
                  </div>
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Língua padrão</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {cookie.language}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Posição do Banner</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {getPositionBanner(cookie.position_controller)}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </TabPanel>
            <TabPanel value={tabPosition} index={2} id="tabpanel-category" ariaLabel="tab-category" boxPadding={1}>
              <DefaultTable
                rows={tableHeaderCategory}
                cells={cookie.categories || []}
                onClickModalDetail={item => {
                  showDisclaimerDetailModal(item);
                }}
                hideDelete
                hideEdit
                hideShow
                showModalDetail
                showNoDataLine
                link=""
              />
            </TabPanel>
            <TabPanel value={tabPosition} index={3} id="tabpanel-consent" ariaLabel="tab-consent" boxPadding={1}>
              <DefaultTable
                rows={tableHeaderConsent}
                cells={cookieConsent?.pages.flatMap(page => page.consents) ?? []}
                hideDelete
                hideShow
                hideEdit
                link=""
              />
              <Stack sx={{ justifyContent: 'center', flexDirection: 'row', marginTop: '16px' }}>
                <ButtonForm
                  label={isFetchingNextPage ? <DotsLoader /> : 'Carregar mais'}
                  onClick={() => fetchNextPage()}
                  variant="link"
                  disabled={!hasNextPage || isFetchingNextPage}
                />
              </Stack>
            </TabPanel>
          </Box>
          <Snackbar
            open={showCopySuccess}
            onClose={() => {
              setShowCopySuccess(false);
            }}
          >
            <Alert
              onClose={() => {
                setShowCopySuccess(false);
              }}
              severity="success"
              sx={{ width: '100%' }}
            >
              Código copiado para área de transferência!
            </Alert>
          </Snackbar>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  );
};
