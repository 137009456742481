export const tableHeaderCategory = [
    {
      id: 'id',
      type: 'text',
      value: 'ID',
    },
    {
        id: 'name',
        type: 'text',
        value: 'Cookie',
    },
    {
        id: 'auto_block',
        type: 'boolean',
        value: 'Bloqueio Automático',
    },
    {
        id: 'action',
        type: 'actions',
        value: '',
    },
    // {
    //     id: 'expires',
    //     type: 'time-expires',
    //     value: 'Expiração',
    // }
]

export const tableHeaderConsent = [
    {
        id: 'id',
        type: 'text',
        value: 'ID Consentimento',
    },
    {
        id: 'status',
        type: 'text',
        value: 'Status',
    },
    {
        id: 'categories',
        type: 'consent-categories',
        value: 'Categorias',
    },
    {
        id: 'country',
        type: 'text',
        value: 'País',
    },
    {
        id: 'version',
        type: 'number',
        value: 'Versão',
    },
    {
        id: 'createdAt',
        type: 'datetime',
        value: 'Dado em',
    }
]