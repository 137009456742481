import { backendService } from "services"

export const getCookies = async (tenant) => {
    return await backendService.get(`/cookie/disclaimer/by-tenant/${tenant}`)
}

export const getCookieById = async (id) => {
    return await backendService.get(`/cookie/disclaimer/by-id/${id}`)
}

export const deleteCookie = async (id) => {
    return await backendService.delete(`/cookie/disclaimer/${id}`)
}

export const addCookie = async (cookie) => {
    return await backendService.post(`/cookie/disclaimer`, cookie)
}

export const editCookie = async (id, cookie) => {
    return await backendService.put(`/cookie/disclaimer/${id}`, cookie)
}

export const getCookieCategoryById = async (id) => {
    return await backendService.get(`/cookie/${id}`)
}

export const getCookieConsentByTenant = async (tenant, params) => {
  let queryParam = '';
  if (params) queryParam = `?lastIds.lastId=${params.lastId}&lastIds.getFromOldDB=${params.getFromOldDB}&lastIds.disclaimerId=${params.disclaimerId}`;
  return await backendService.get(`/cookie/disclaimer/consent/${tenant}${queryParam}`)
}

export const checkDisclaimerInstall = async (disclaimerId) => {
  return await backendService.post(`/cookie/disclaimer/check-install/${disclaimerId}`)
}

export const createDisclaimerScan = async (disclaimerId) => {
  return await backendService.post(`/cookie/disclaimer/create-scan/${disclaimerId}`)
}