import React from "react";
import { Box, Typography } from "@mui/material";

export const StatusProgressBar = ({ statuses }) => {
  const total = statuses.reduce((acc, status) => acc + status.count, 0);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "16px",
        width: "100%",
      }}
    >
      {/* Lista de Status */}
      <Box sx={{ flex: 1, minWidth: "200px" }}>
        {statuses.map((status) => (
          <Box key={status.label} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "4px" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: status.color,
                  borderRadius: "4px",
                }}
              />
              <Typography variant="body2">{status.label}</Typography>
            </Box>
            <Typography variant="body2" fontWeight="bold">{status.count}</Typography>
          </Box>
        ))}
      </Box>

      {/* Barra de Progresso */}
      <Box sx={{ flex: 3, marginLeft: "16px", position: "relative", height: "16px", borderRadius: "8px", backgroundColor: "#ddd", display: "flex", overflow: "hidden" }}>
        {statuses.map((status) => {
          const width = total > 0 ? (status.count / total) * 100 : 0;
          return width > 0 ? (
            <div
              key={status.label}
              style={{
                width: `${width}%`,
                backgroundColor: status.color,
                height: "100%",
              }}
            />
          ) : null;
        })}
      </Box>

      {/* Total */}
      <Box sx={{ marginLeft: "16px", textAlign: "center" }}>
        <Typography variant="h6" color="primary" fontWeight="bold">
          {total}
        </Typography>
        <Typography variant="body2" color="primary">
          TOTAL
        </Typography>
      </Box>
    </Box>
  );
};

export default StatusProgressBar;
