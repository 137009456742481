import { FormSuccess } from 'Components/Form/Success';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from '../../../Components/Container';
import { Configuration } from './Configuration';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useMutation, useQuery } from 'react-query';
import { PageLoaderForm } from 'Components/PageLoader/Form';
import { addCookie, editCookie, getCookieById } from 'services/cookies-service';

export const CookiesForm = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const { id } = useParams();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [idDisclaimer, setIdDisclaimer] = useState(null);
  const [finished, setFinished] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      url: '',
      backgroundColor: '',
      primaryColor: '',
      textColor: '',
      positionController: '',
    },
  });

  const formatDisclaimerForm = dados => {
    return {
      name: dados.url.replace('.', '').replace('.', '').replace('http://', '').replace('https://', ''),
      domains: [dados.url],
      backgroundColor: dados.backgroundColor,
      positionController: dados.positionController,
      primaryColor: dados.primaryColor,
      textColor: dados.textColor,
      tenant: selectedTenant.slug,
    };
  };

  const formatEditDisclaimerForm = dados => {
    return {
      name: dados.url.replace('.', '').replace('.', '').replace('http://', '').replace('https://', ''),
      domains: [dados.url],
      backgroundColor: dados.backgroundColor,
      positionController: dados.positionController,
      primaryColor: dados.primaryColor,
      textColor: dados.textColor,
      tenant: selectedTenant.slug,
    };
  };

  const mutation = useMutation(
    () => {
      return id ? editCookie(id, formatEditDisclaimerForm(getValues())) : addCookie(formatDisclaimerForm(getValues()));
    },
    {
      onError: (error, _variables, _context) => {
        const errorMessage = error.response.data.message || error.message;
        // An error happened!
        SwalReact.fire({
          title: 'Erro!',
          text: errorMessage,
          icon: 'error',
        });
      },
      onSuccess: res => {
        setIdDisclaimer(res.data.disclaimerId ?? id);
        setFinished(true);
      },
    },
  );

  const handleComplete = () => {
    mutation.mutate();
  };

  const handleReset = () => {
    reset();
    setFinished(false);
    setIdDisclaimer(null);
    navigate('/cookies/add');
  };

  const {
    isLoading,
    error,
    data: cookieData,
  } = useQuery(['getCookie', id], async () => {
    if (!id) return null;

    const resCookie = await getCookieById(id);

    if (!resCookie.data) return null;

    return resCookie.data;
  });

  useEffect(() => {
    if (!id) return;
    if (!cookieData) return;

    setValue('url', cookieData.domain);
    setValue('backgroundColor', cookieData.background_color);
    setValue('primaryColor', cookieData.primary_color);
    setValue('textColor', cookieData.text_color);
    setValue('positionController', cookieData.position_controller);

    return () => {};
  }, [id, cookieData, setValue]);

  const showForm = () => {
    if (id) return !isLoading && !error;
    return true;
  };

  return (
    <Container
      title={`${id ? 'Editar' : 'Adicionar'} Cookie e Consentimento`}
      breadcrumb="Adequações / Gestão de Cookies e Consentimento"
      linkPage="/cookies"
    >
      {isLoading && <PageLoaderForm />}
      {showForm() && (
        <>
          <div className="card-content">
            {!finished && (
              <Configuration
                control={control}
                id={id}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                getValues={getValues}
                handleComplete={handleComplete}
                loading={mutation.isLoading}
              />
            )}
            {finished && (
              <FormSuccess
                title="Cookie cadastrado com sucesso!"
                labelStartAgain="Cadastrar novo cookie"
                handleStartAgain={() => handleReset()}
                labelShow="Ver detalhes"
                handleShow={() => navigate('/cookies/show/' + idDisclaimer)}
              />
            )}
          </div>
        </>
      )}
    </Container>
  );
};
