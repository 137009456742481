import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  getGapAnalysisControlDetails,
  updateStatusControl,
  updateMaturityLevel,
  updateStatusObjectiveControl,
} from '../../../../services/gap-analysis';
import { PageLoaderShow } from '../../../../Components/PageLoader/Show';
import { Container } from 'Components/Container';
import { Card } from 'Components/Card';
import React, { useEffect, useState } from 'react';
import {
  CardContent, Typography, Select, MenuItem, Button,
  Accordion, AccordionSummary, AccordionDetails, Chip, FormControl, InputLabel, Snackbar, Alert,
} from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Title } from './styles'

import { Column, Row  } from '../../../../Components/Grid';
import { useSelector } from 'react-redux';
import { useAuthTenant } from '../../../../store/auth';
import { getCompanyById } from '../../../../services/company-service';
import { getAssetById } from '../../../../services/assets-service';
import { getAreaById } from '../../../../services/areas-service';
import { getProcessById } from '../../../../services/processes-service';
import { getSystemById } from '../../../../services/systems-service';

const options = [
  { label: "Empresa", value: "companies", fetchFunction: getCompanyById },
  { label: "Áreas de negócio", value: "areas", fetchFunction: getAreaById },
  { label: "Processos", value: "processes", fetchFunction: getProcessById },
  { label: "Sistemas", value: "systems", fetchFunction: getSystemById },
  { label: "Ativos", value: "assets", fetchFunction: getAssetById },
];


export const GapAnalysisControlDetalShow = () => {
  const { id } = useParams();
  const selectedTenant = useSelector(useAuthTenant);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [statuses, setStatuses] = useState({});
  const [currentLevel, setCurrentLevel] = useState("notPerformed");
  const [selectedStatus, setSelectedStatus] = useState("noResponse");
  const [entityData, setEntityData] = useState(null);


  const {
    isLoading,
    error,
    data: control,
  } = useQuery('getGapAnalysisControlDetails', async () => {
    const data = await getGapAnalysisControlDetails(id);
    return data.status === 200 ? data.data : null;
  });



  useEffect(() => {
    if (control?.objectives) {
      setStatuses(control.objectives.reduce((acc, obj) => ({ ...acc, [obj._id]: obj.status }), {}));
    }
    if (control && control.maturity_level) {
      setCurrentLevel(control.maturity_level);
    }
    if (control && control.status) {
      setSelectedStatus(control.status);
    }
    if (control?.entity_type && control?.entity_id) {
      const selectedOption = options.find(option => option.value === control.entity_type);
      if (selectedOption) {
        selectedOption.fetchFunction(control.entity_id)
          .then(response => setEntityData(response.data))
          .catch(error => console.error("Erro ao buscar entidade:", error));
      }
    }

  }, [control]);
  const getStatusProps = (status) => {
    switch (status) {
      case "attended":
        return { label: "Atendido", color: "success", icon: <CheckCircleIcon style={{ color: "green", marginTop: "5px" }} /> };
      case "notAttended":
        return { label: "Não Atendido", color: "error", icon: <CancelIcon style={{ color: "red", marginTop: "5px" }} /> };
      case "notApplicable":
        return { label: "Não Aplicável", color: "success", icon: <CheckCircleIcon style={{ color: "green", marginTop: "5px" }} /> };
      default:
        return { label: "Não Respondido", color: "default", icon: <RadioButtonUncheckedIcon style={{ color: "#B0B0B0", marginTop: "5px" }} /> };
    }
  };

  const handleUpdateMaturityLevel = async (idControl, maturityLevel) => {
    if (!maturityLevel) {
      setSnackbar({ open: true, message: "Please select a maturity level.", severity: "warning" });
      return;
    }

    try {
      await updateMaturityLevel(idControl, maturityLevel);
      setSnackbar({ open: true, message: "O nível de maturidade foi atualizado com sucesso!", severity: "success" });
    } catch (error) {
      console.error("Error updating maturity level:", error);
      setSnackbar({ open: true, message: "Erro ao atualizar o nível de maturidade.", severity: "error" });
    }
  };

  const maturityLevels = [
    { key: "notPerformed", name: "Nível 1" },
    { key: "performedInformally", name: "Nível 2" },
    { key: "plannedTracked", name: "Nível 3" },
    { key: "wellDefined", name: "Nível 4" },
    { key: "quantitativelyControlled", name: "Nível 5" },
    { key: "continuouslyImproving", name: "Nível 6" }
  ];

  const statusOptions = [
    { id: "noResponse", name: "Sem resposta" },
    { id: "deficient", name: "Deficiente" },
    { id: "implemented", name: "Implementado" },
    { id: "readyForReview", name: "Pronto para revisão" },
    { id: "monitored", name: "Monitorado" },
    { id: "notApplicable", name: "Não Aplicável" },
  ];

  const typeLabels = {
    areas: "Áreas de Negócio",
    processes: "Processos",
    systems: "Sistemas",
    assets: "Ativos",
    companies: "Empresa",
  };

  const handleStatusControlChange = async (newStatus) => {
    setSelectedStatus(newStatus);
    try {
      await updateStatusControl(id, newStatus);
      setSnackbar({ open: true, message: "O status do controle foi atualizado com sucesso!", severity: "success" });
    } catch (error) {
      console.error("Error updating status:", error);
      setSnackbar({ open: true, message: "Erro ao atualizar o status do controle.", severity: "error" });
    }
  };


  const handleStatusChange = async (idObjective, newStatus) => {
    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [idObjective]: newStatus, // Atualiza o estado localmente
    }));

    try {
      await updateStatusObjectiveControl(idObjective, newStatus);
      setSnackbar({ open: true, message: "O status do objetivo foi atualizado com sucesso!", severity: "success" });
    } catch (error) {
      console.error("Error updating status:", error);
      setSnackbar({ open: true, message: "Erro ao atualizar o status do objetivo.", severity: "error" });
    }
  };

  const employeeSize = parseFloat(selectedTenant.size);

  const getSolutionKey = (size) => {
    if (size >= 1 && size <= 9) return "solutionsMicroSmall";
    if (size >= 10 && size <= 49) return "solutionsSmall";
    if (size >= 50 && size <= 249) return "solutionsMedium";
    if (size >= 250 && size <= 999) return "solutionsLarge";
    if (size >= 1000) return "solutionsEnterprise";
    return null;
  };
  const solutionKey = getSolutionKey(employeeSize);
  return (
    <>
      {!isLoading && !error && (
        <Container
          title={`${control.controlDetail.scfControl}`}
          breadcrumb={`Assessment / Gap Analysis / ${control.controlDetail.scfControl}`}
          linkPage={`/gap-analysis/show/${control?.gapanalysis_id || "ID_Nao_Encontrado"}`}
          actionButton={
            <>
              <FormControl variant="outlined" style={{ minWidth: 200 }}>
                <InputLabel id="status-label" shrink={true}>
                  Status
                </InputLabel>

                <Select
                  labelId="status-label" notched label="Status"
                  value={selectedStatus}
                  variant="outlined"
                  onChange={(e) => handleStatusControlChange(e.target.value)}
                  sx={{
                    borderRadius: '40px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '40px',
                    }
                  }}
                >
                  {statusOptions.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          }
        >
          <Row className="px-2 mt-3 mb-3">
            <Column xs={12}>
              <Card>
                <Title className="m-0 mb-3">Detalhes da Entidade</Title>

                <div style={{ display: "flex", gap: "16px", marginBottom: "16px" }}>
                  <Typography><strong>Nome:</strong> {entityData?.name || "Não disponível"}</Typography>
                  <Typography><strong>Criticidade:</strong> {entityData?.criticity || "Não informada"}</Typography>
                  <Typography><strong>Tipo:</strong> {typeLabels[control?.entity_type] || "Não informada"}</Typography>
                </div>

                <Typography>
                  <strong>Descrição:</strong> {entityData?.description || "Sem descrição disponível"}
                </Typography>
              </Card>

            </Column>
          </Row>

          <Row className="px-2 mt-3 mb-3">
            <Column xs={12}>
              <Card>
                <Title className="m-0 mb-3">Visão Geral</Title>
                <div style={{ display: "flex", gap: "16px", marginBottom: "16px" }}>
                  <Typography><strong>Domínio:</strong> {control.controlDetail.scfDomain}</Typography>
                  <Typography><strong>Agrupamento de Funções:</strong> <span>{control.controlDetail.nistCsfFunctionGrouping}</span></Typography>
                  <Typography><strong>Aplicabilidade:</strong> {control.controlDetail.applicabilityStatus} </Typography>
                  <Typography><strong>Peso de Controle:</strong> {control.controlDetail.controlWeighting} </Typography>
                </div>

                <Typography>
                  <strong>Descrição do Controle:</strong> {control.controlDetail.scfControlDescription}
                </Typography>
                <Typography style={{ marginTop: "16px" }}>
                  <strong>Requisitos:</strong>
                </Typography>
                {control.frameworkDetails.map((framework) => (
                  <Typography key={framework._id}>
                    {framework.name}: {control.controlDetail.frameworks[framework._id]?.join(", ")}
                  </Typography>
                ))}

                <Typography style={{ marginTop: "16px" }}>
                  <strong>Métodos para cumprir:</strong>
                  <Typography style={{ whiteSpace: "pre-line" }}>
                    {control.controlDetail[solutionKey]}
                  </Typography>
                </Typography>

              </Card>
            </Column>
          </Row>
          <Row className="px-2 mt-3 mb-3">
            <Column xs={12}>
              <Card>
                <Title className="m-0">Objetivos</Title>
                <CardContent sx={{ maxHeight: "300px", overflowY: "auto" }}>
                  {control.objectives.map((obj) => {

                    const statusProps = getStatusProps(statuses[obj._id] || "notResponded");
                    return (
                      <Accordion key={obj._id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                          <Typography>{obj.details.scfAssessmentObjective}</Typography>
                          <Chip
                            label={statusProps.label}
                            color={statusProps.color}
                            style={{ marginLeft: "auto", width:'auto'}}
                          />
                          {statusProps.icon}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{obj.details.scfAoId}</Typography>
                          <Select
                            value={statuses[obj._id] || "notResponded"}
                            onChange={(e) => handleStatusChange(obj._id, e.target.value)}
                            fullWidth
                            style={{ marginTop: "16px" }}
                            variant="outlined"
                          >
                            <MenuItem value="notResponded">Não Respondido</MenuItem>
                            <MenuItem value="attended">Atendido</MenuItem>
                            <MenuItem value="notAttended">Não Atendido</MenuItem>
                            <MenuItem value="notApplicable">Não Aplicável</MenuItem>
                          </Select>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </CardContent>
              </Card>
            </Column>
          </Row>
          <Row className="px-2 mt-3 mb-3">
            <Column xs={12}>
              <Card style={{ padding: "16px" }}>
                <Title className="m-0">Nível de Maturidade</Title>
                <CardContent>
                  <div style={{ marginTop: "16px" }}>
                    <Typography variant="body1" fontWeight="bold">Nível de Maturidade Atual:</Typography>
                    <Select
                      id="maturitySelect"
                      value={currentLevel}
                      onChange={(e) => setCurrentLevel(e.target.value)}
                      fullWidth
                      style={{ marginTop: "8px" }}
                      variant="outlined"
                    >
                      {maturityLevels.map((level) => (
                        <MenuItem key={level.key} value={level.key}>{level.name}</MenuItem>
                      ))}
                    </Select>

                    <Button
                      variant="contained"
                      sx={{ mt: 2 }}
                      onClick={() => handleUpdateMaturityLevel(id, currentLevel)}
                    >
                      Update
                    </Button>
                  </div>

                  <div style={{ marginTop: "16px" }}>
                    <Typography variant="body1" fontWeight="bold">{maturityLevels.find(l => l.key === currentLevel)?.name}</Typography>
                    <Typography variant="body2" color="textSecondary" style={{ whiteSpace: "pre-line" }}>
                      {control.controlDetail[currentLevel]}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Column>
            <Snackbar
              open={snackbar.open}
              autoHideDuration={3000}
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          </Row>

        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  )
}