import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import { Container } from 'Components/Container';
import { Information } from 'Components/Information';
import React from 'react';
import { DefaultTable } from '../../Components/DefaultTable';
import { tableHeader } from './table-header.js';
import { useQueries } from 'react-query';
import { getGapAnalysis } from 'services/gap-analysis';

export const GapAnalysis = () => {
  const selectedTenant = useSelector(useAuthTenant);

  const results = useQueries([
    {
      queryKey: ['companyKey', selectedTenant._id],
      queryFn: () => getGapAnalysis(selectedTenant._id),
      enabled: !!selectedTenant._id, // Só busca se houver um ID válido
    },
  ]);
  const gapAnalysisData = results[0].data?.data || [];

  return (
    <Container title="Gap Analysis" breadcrumb="Assessment / Gap Analysis" showButtonAdd linkPage="/gap-analysis">
      <Information
        title={'Gap Analysis'}
        description={
          <p className="m-0">
            <strong>Gap Analysis</strong> é uma ferramenta essencial para identificar discrepâncias entre o estado atual
            e o estado desejado de um processo, sistema ou organização. Ele pode abranger diferentes áreas, incluindo:
            <br />
            <br />
            &emsp;• <strong>Processos:</strong> Avaliação de fluxos de trabalho, ineficiências e oportunidades de
            melhoria.
            <br />
            &emsp;• <strong>Tecnologia:</strong> Identificação de deficiências em infraestrutura, softwares, segurança e
            integração de sistemas.
            <br />
            &emsp;• <strong>Conformidade:</strong> Análise de aderência a normas, regulamentos e políticas internas.
            <br />
            <br />A análise de gaps permite definir ações estratégicas para eliminar falhas e otimizar a operação.
          </p>
        }
        style={{
          marginTop: '24px',
        }}
      />
      <DefaultTable applyMB rows={tableHeader} cells={gapAnalysisData} hideEdit hideDelete link="gap-analysis" />
    </Container>
  );
};
