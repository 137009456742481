import { Column, Row } from 'Components/Grid'
import styled from 'styled-components'

export const RowExhibition = styled(Row)`
  border-bottom: 1px solid #ebecf8;
  min-height: 38px;
`

export const RowExhibitionLight = styled(Row)`
  border-bottom: 1px solid #ffffff;
  min-height: 38px;
`

export const ColumnEdit = styled(Column)`
  display: flex;
`
export const LinkEdit = styled.button`
  background: transparent;
  border: none;
  height: fit-content;
  width: fit-content;
  margin: 0px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'liga' off;
  color: #0039c5;
  &:hover {
    cursor: pointer;
  }
`

export const ContainerIcon = styled.div`
  background-color: ${props => props.active?'#537de4':'#4b90fe'};
  width: 44px;
  height: 44px;
  border-radius: 12px;
  &:hover {
    cursor: pointer;
  }
`
export const CardStyled = styled.div`
  border: solid 2px #ebebeb !important;
  border-radius: 8px;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-inline: 1rem;
`

export const TitleModalDetail = styled.div`
  font-weight: 700;
  font-size: 18px;
  text-align: start;
  margin: 0;
`

export const SubTitleModalDetail = styled.div`
  --tw-text-opacity: 1;
  color: rgb(163 176 201 / var(--tw-text-opacity, 1));
  text-align: start;
  margin: 0;
  font-size: 14px;
`