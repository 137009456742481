import { FormControl, FormHelperText, FormLabel, InputLabel, MenuItem, Select } from '@mui/material';
import DotsLoader from 'Components/DotsLoader';
import { ButtonForm, CustomTextField } from 'Components/Form/styles';
import { Column, Row } from 'Components/Grid';
import { MuiColorInput } from 'mui-color-input';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const Configuration = ({ id, register, control, handleSubmit, errors, getValues, handleComplete, loading }) => {
  const navigate = useNavigate();
  const onSubmit = () => {
    handleComplete();
  };

  const positions = [
    {
      value: 'left',
      description: 'Esquerda',
    },
    {
      value: 'right',
      description: 'Direita',
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Column>
          <FormControl component="fieldset">
            <FormLabel component="legend" sx={{ fontWeight: 'bold', marginBottom: '24px' }}>
              Configurações
            </FormLabel>
          </FormControl>
        </Column>
      </Row>
      <Row justifyContent="center" className="mb-3">
        <Column xs={12} md={6} className="d-flex">
          <CustomTextField
            {...register('url', { required: 'URL é obrigatório' })}
            label="Site"
            autoFocus
            error={errors?.url ? true : false}
            sx={{ minHeight: '48px', maxHeight: '48px', margin: '0 auto', width: '100%' }}
            helperText={errors?.url ? errors.url.message : null}
            InputLabelProps={{ shrink: true }}
            disabled={!!id}
          />
        </Column>
        <Column xs={12} md={6} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues('positionController') !== '';
              },
              required: 'Posição é obrigatório',
            }}
            name="positionController"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ margin: '0' }} error={errors?.positionCard ? true : false}>
                <InputLabel id="position-controller-label" shrink={true}>
                  Posição do Banner
                </InputLabel>
                <Select
                  labelId="position-controller-label"
                  id="positionController"
                  value={value ?? ''}
                  label="Posição do Banner"
                  onChange={onChange}
                  notched
                  sx={{ '> #positionController': { padding: '11.5px 14px' } }}
                >
                  <MenuItem value={''} key={-1}>
                    Selecione uma posição
                  </MenuItem>
                  {positions?.map(item => (
                    <MenuItem value={`${item.value}`} key={`position-${item.value}`}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors?.positionCard ? errors.positionCard.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
      </Row>
      <Row className="mb-3">
        <Column xs={12} md={4} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues('primaryColor') !== '';
              },
              required: 'Cor Principal é obrigatório',
            }}
            name="primaryColor"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth error={errors?.primaryColor ? true : false}>
                <InputLabel id="primary-Color-label" shrink={true}>
                  Cor Principal
                </InputLabel>
                <MuiColorInput label="Cor Principal" format="hex" value={value} onChange={onChange} isAlphaHidden />
                <FormHelperText>{errors?.primaryColor ? errors.primaryColor.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
        <Column xs={12} md={4} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues('backgroundColor') !== '';
              },
              required: 'Cor Fundo é obrigatório',
            }}
            name="backgroundColor"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth error={errors?.backgroundColor ? true : false}>
                <InputLabel id="background-color-label" shrink={true}>
                  Cor Fundo
                </InputLabel>
                <MuiColorInput label="Cor Fundo" format="hex" value={value} onChange={onChange} isAlphaHidden />
                <FormHelperText>{errors?.backgroundColor ? errors.backgroundColor.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
        <Column xs={12} md={4} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues('textColor') !== '';
              },
              required: 'Cor Texto é obrigatório',
            }}
            name="textColor"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth error={errors?.textColor ? true : false}>
                <InputLabel id="text-color-label" shrink={true}>
                  Cor Texto
                </InputLabel>
                <MuiColorInput label="Cor Texto" format="hex" value={value} onChange={onChange} isAlphaHidden />
                <FormHelperText>{errors?.textColor ? errors.textColor.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm
            secondary
            id="cancel"
            label="cancelar"
            onClick={() => navigate('/cookies')}
            disabled={loading}
          ></ButtonForm>
        </Column>
        <Column xs={2} justifyContent="center" className="d-flex">
          <ButtonForm label={loading ? <DotsLoader /> : 'Salvar'} variant="submit" disabled={loading} />
        </Column>
      </Row>
    </form>
  );
};
