import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";

export const sideBarData = [
  {
    title: "Gerenciar",
    icon: "/img/icons/gear.svg",
    iconAlt: "Ícone de engrenagem",
    permissions: ["company_profile_r", "offices_r", "user_group_r", "users_r"],
    children: [
      {
        title: "Perfil da empresa",
        link: "/company-profile",
        permission: "company_profile_r",
      },
      { title: "Escritórios", link: "/offices", permission: "offices_r" },
      {
        title: "Grupos de usuários",
        link: "/users-groups",
        permission: "user_group_r",
      },
      { title: "Usuários", link: "/users", permission: "users_r" },
    ],
    link: "#",
  },
  {
    title: "Mapeamento",
    icon: "/img/icons/signature.svg",
    iconAlt: "Ícone de documento sendo assinado",
    permissions: [
      "general_map_r",
      "areas_r",
      "processes_r",
      "systems_r",
      "data_r",
      "assets_r"
    ],
    children: [
      { title: "Mapeamento geral", link: "/map", permission: "general_map_r" },
      { title: "Áreas de negócio", link: "/areas", permission: "areas_r" },
      { title: "Processos", link: "/processes", permission: "processes_r" },
      { title: "Sistemas", link: "/systems", permission: "systems_r" },
      { title: "Ativos", link: "/assets", permission: "assets_r" },
      { title: "Tipos de dados", link: "/data", permission: "data_r" },
    ],
    link: "#",
  },
  {
    title: "Adequações",
    icon: "/img/icons/file3.svg",
    iconAlt: "Ícone de documento",
    permissions: ["policies_r"],
    children: [
      // {title: 'Padrões de segurança', link: '#', permission: 'users_r'},
      { title: "Políticas", link: "/policies", permission: "policies_r" },
      {title: 'Gestão de Cookies e Consentimento', link: '/cookies', permission: 'users_r'},
      // {title: 'Relatório de controles', link: '#', permission: 'users_r'}
    ],
    link: "#",
  },
  {
    title: "Assessment",
    icon: "/img/icons/chart.svg",
    iconAlt: "Ícone de relatório",
    permissions: ["reports_ropa_r", "assessment_gap_analysis_r"],
    children: [
      {
        title: "Gap Analysis",
        link: "/gap-analysis",
        permission: "assessment_gap_analysis_r",
      },
      {
        title: "ASM",
        link: "/asm",
        permission: "reports_ropa_r",
      },
    ],
    link: "#",
  },
  {
    title: "Relatórios",
    icon: "/img/icons/report.svg",
    iconAlt: "Ícone de relatório",
    permissions: ["reports_ropa_r"],
    children: [
      {
        title: "Ropa",
        link: "/reports/ropa",
        permission: "reports_ropa_r",
      },
      // {
      //   title: "Gap Analysis",
      //   link: "/reports/gap-analysis",
      //   permission: "reports_gap_analysis_r",
      // },
    ],
    link: "#",
  },
];

export const sideBarDataSuperAdmin = [
  {
    title: "Configurações Globais",
    icon: <LanguageOutlinedIcon />,
    iconAlt: "Ícone do globo",
    permissions: ["company_r", "global_control_r", "global_standards_r", "audit_logs_r"],
    children: [
      { title: "Clientes", link: "/companies", permission: "company_r" },
      // { title: "Controles", link: "/controls", permission: "global_control_r" },
      // {
      //   title: "Padrões",
      //   link: "/security-standards",
      //   permission: "global_standards_r",
      // },
      {
        title: "Logs",
        link: "/audit-logs",
        permission: "audit_logs_r",
      },
    ],
  },
];

export const sideBarDataPartners = [
  {
    title: "Parceiros",
    icon: <HandshakeOutlinedIcon />,
    iconAlt: "Ícone do globo",
    permissions: ["partner_company_r"],
    children: [
      {
        title: "Clientes",
        link: "/companies-partner",
        permission: "partner_company_r",
      },
    ],
  },
];
