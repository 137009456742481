import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DomainControlsList = ({ data }) => {
  const [expanded, setExpanded] = useState(null);

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>
      {data.map((section) => (
        <Accordion
          key={section.id}
          expanded={expanded === section.id}
          onChange={handleExpand(section.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: "#1976d2",
              color: "white",
              fontWeight: "bold",
            }}
          >
            <Typography sx={{ flexGrow: 1 }}>{section.title}</Typography>
            <Typography sx={{ fontWeight: "bold", color: "lightgray" }}>
              ({section.count})
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            {section.children.length > 0 ? (
              section.children.map((item) => (
                <Box key={item.id} sx={{ padding: "8px 16px", borderBottom: "1px solid #ddd" }}>
                  <Typography variant="subtitle1">{item.name}</Typography>
                  {item.description && (
                    <Typography variant="body2" color="textSecondary">
                      {item.description}
                    </Typography>
                  )}
                </Box>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                Nenhum item disponível.
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default DomainControlsList;
